import axios from 'axios';
import { jwt } from './api_call';
import { device_store } from '@/models/root/device';

export function get(config, headers) {
  const { url, ...other } = config;
  return axios.get(url, {
    ...other,
    headers,
  });
}

export function deleteApiCall(config, headers) {
  const { url } = config;
  return axios.delete(url, { headers });
}

export function put(config, headers) {
  const { url, updateParams } = config;
  return axios.put(url, updateParams, { headers });
}

export function post(config, headers) {
  const { url, params } = config;
  return axios.post(url, params, { headers });
}

export function upload(config, headers) {
  const { url, formData } = config;
  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...headers,
    },
  });
}

export function apiCall(type, config) {
  const device_header = device_store.header;

  let headers = !jwt
    ? {}
    : {
        ...jwt,
      };

  if (device_header) {
    headers.device = device_header;
  }

  switch (type) {
    case 'get':
      return get(config, headers);
    case 'upload':
      return upload(config, headers);
    case 'put':
      return put(config, headers);
    case 'post':
      return post(config, headers);
    case 'delete':
      return deleteApiCall(config, headers);

    default:
      return get(config, headers);
  }
}
