import classNames from 'classnames';
import Flex, { type FlexProps } from '@/ui-kit/flex';
import DoubleCheckIcon from '@/ui-kit/assets/DoubleCheck.svg?react';
import NewIcon from '@/ui-kit/assets/New.svg?react';
import FastTagIcon from '@/ui-kit/assets/FastTag.svg?react';
import styles from './shipfast.module.less';
import { ellipsisWithTooltip } from '@/pages/payments/utils/common';

export type PendingPageStatusTags = 'invoiced' | 'pending' | 'processing';

type NewOrderStatusTagProps = {
  status: PendingPageStatusTags;
};

interface NewTagProps extends FlexProps {
  text: string;
  IconComponent: React.ReactNode;
  textClassNames?: string;
  showEllipsis?: boolean;
}

export const NewTag = ({
  text,
  IconComponent,
  textClassNames,
  showEllipsis = true,
  ...restProps
}: NewTagProps) => {
  const { className, ...restFlexProps } = restProps;
  return (
    <Flex
      align="center"
      gap={5}
      className={classNames(styles.tagContainer, className)}
      {...restFlexProps}
    >
      {IconComponent}
      <span className={classNames(styles.text, textClassNames)}>
        {showEllipsis ? ellipsisWithTooltip(text, 10) : text}
      </span>
    </Flex>
  );
};

export const NewOrderStatusTag = ({ status }: NewOrderStatusTagProps) => {
  const isProcessing = status === 'processing';
  const isInvoiced = status === 'invoiced';
  const text = isInvoiced ? 'Invoiced' : isProcessing ? 'Processing' : 'New';
  const IconComponent = isInvoiced ? <DoubleCheckIcon /> : <NewIcon />;

  return (
    <NewTag
      text={text}
      IconComponent={IconComponent}
      className={
        isInvoiced ? styles.invoicedTagContainer : styles.newTagContainer
      }
    />
  );
};

export const ConnectedStatusTag = ({
  text = 'Connected',
}: {
  text?: string;
}) => {
  const IconComponent = <DoubleCheckIcon />;

  return (
    <NewTag
      text={text}
      IconComponent={IconComponent}
      className={styles.invoicedTagContainer}
      showEllipsis={false}
    />
  );
};

export const FastTag = () => {
  return (
    <NewTag
      text="Fast"
      className={styles.newTagContainer}
      IconComponent={<FastTagIcon />}
      textClassNames={styles.fastTagText}
    />
  );
};
