import { useShipfastWarehousePickupList } from '@/swr/shipfast';
import { Checkbox } from 'antd';
import Flex from '@/ui-kit/flex';
import { Loader } from '@/ui-kit';
import { useQuery } from '@/hooks/useQuery';

import WarehouseInfo from '../../warehouse_info';

import { useShipfastOrderFilters } from '../utils';

import styles from '../shipfast_order_filters.module.less';

const PickupAddressFilter = () => {
  const { appendParams, removeMultiValueParam } = useQuery();
  const { shipfastWarehousePickupList, isLoading } =
    useShipfastWarehousePickupList();
  const { selectedWarehouseIds } = useShipfastOrderFilters();

  if (isLoading) return <Loader />;

  return (
    <Flex vertical gap={20}>
      {shipfastWarehousePickupList.map((warehouse) => (
        <Checkbox
          key={warehouse.id}
          value={warehouse.id}
          className={styles.filterCheckbox}
          checked={selectedWarehouseIds?.includes(warehouse.id)}
          onChange={(e) => {
            const value = e.target.value;
            if (e.target.checked) {
              appendParams({
                warehouseId: [...selectedWarehouseIds, value].join(','),
              });
            } else {
              removeMultiValueParam({ key: 'warehouseId', value });
            }
          }}
        >
          <WarehouseInfo warehouseId={warehouse.id} showFullAddress />
        </Checkbox>
      ))}
    </Flex>
  );
};

export default PickupAddressFilter;
