export const COMPANY_TYPES = [
  {
    name: 'Sole Proprietorship',
    initials: 'SP',
    key: 'proprietary',
  },
  {
    name: 'Partnership',
    initials: 'P',
    key: 'partnership',
  },
  {
    name: 'Limited Liability Partnership',
    initials: 'LLP',
    key: 'llp',
  },
  {
    name: 'Private Limited',
    initials: 'LTD',
    key: 'private',
  },
];

export const getDocument = (documents = [], documentName) => {
  return documents.flatMap((doc) => {
    const { documents: subDocs = [] } = doc;
    if (doc.document_name === documentName && subDocs.length) {
      return subDocs.map((subDoc) => ({ ...doc, documents: [subDoc] }));
    }
    return doc.document_name === documentName ? [doc] : [];
  });
};

export const getDocumentFromArray = (documents, documentName) => {
  return documents.find((document) => document.document_name === documentName);
};

export const getDocumentTypeFromArray = (documentTypes, documentName) => {
  return documentTypes.find((document) => document.key === documentName);
};

export const getDocumentTypesArray = (documentTypes, documentNames) => {
  return documentTypes.filter((doc) => documentNames.includes(doc.key));
};

export function updateShareholder(signature, type, shareholderMap) {
  if (!shareholderMap[signature.shareholder.uuid]) {
    shareholderMap[signature.shareholder.uuid] = {
      uuid: signature.shareholder.uuid,
      shareholderName: signature.shareholder.full_name,
      shareholderEmail: signature.shareholder.email,
    };
  }
  shareholderMap[signature.shareholder.uuid][`${type}EsignLink`] =
    signature.esign_service_link;
  shareholderMap[signature.shareholder.uuid][`${type}EsignStatus`] =
    signature.status;
}

export function getFormattedShareholderList(
  agreement_signatures,
  kyc_signatures,
  addendum_signatures,
) {
  const shareholderMap = {};

  agreement_signatures.forEach((signature) => {
    updateShareholder(signature, 'combined', shareholderMap);
  });

  kyc_signatures.forEach((signature) => {
    updateShareholder(signature, 'kyc', shareholderMap);
  });

  addendum_signatures.forEach((signature) => {
    updateShareholder(signature, 'addendum', shareholderMap);
  });

  const shareholderList = Object.values(shareholderMap);

  let isKycEsigned = true,
    isCombinedEsigned = true,
    isAddendumEsigned = true;

  shareholderList.forEach((shareholder) => {
    const { combinedEsignStatus, kycEsignStatus, addendumEsignStatus } =
      shareholder;

    isCombinedEsigned =
      isCombinedEsigned &&
      (!combinedEsignStatus || combinedEsignStatus === 'signed');
    isKycEsigned =
      isKycEsigned && (!kycEsignStatus || kycEsignStatus === 'signed');
    isAddendumEsigned =
      isAddendumEsigned &&
      (!addendumEsignStatus || addendumEsignStatus === 'signed');
  });

  return {
    shareholderList: shareholderList,
    isSigned: isKycEsigned && isCombinedEsigned && isAddendumEsigned,
  };
}
