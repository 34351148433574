import { queryParamsToObject } from '@/utils';
import { removeEmptyStringFromArray } from '@/ve-utils/common';
import { useSearchParams } from 'react-router-dom';

export const useQuery = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  const appendParams = (params: any) =>
    setSearchParams({ ...queryParamsToObject(searchParams), ...params });
  const query = queryParamsToObject(searchParams);
  const removeMultiValueParam = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {
    const selectedKeyList = removeEmptyStringFromArray(
      query[key]?.split(',') || [],
    );
    appendParams({
      [key]: selectedKeyList.filter((val) => val !== value).join(','),
    });
  };
  return {
    searchParams,
    setSearchParams: (params: any) => {
      const modifiedParams: any = {};
      Object.entries(params).forEach(([key, value]) => {
        if (value == null) return;
        modifiedParams[key] = value;
      });
      setSearchParams(modifiedParams);
    },
    query,
    resetParams: () => setSearchParams({}),
    appendParams: (params: any) => {
      setSearchParams({ ...queryParamsToObject(searchParams), ...params });
    },
    removeMultiValueParam,
    removeParam: (key: string) => {
      appendParams({ [key]: '' });
    },
    clearParams: () => {
      setSearchParams({});
    },
  };
};
