import { useQuery } from '@/hooks/useQuery';
import { FormikInput, LabeledInput } from '@/ui-kit';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const CityFilter = () => {
  const { query, appendParams } = useQuery();
  const [search, setSearch] = useState('');
  const city = query.city;

  const debouncedSearch = useDebouncedCallback((value: string) => {
    const params = {
      city: value,
    };

    appendParams(params);
  }, 1500);

  useEffect(() => {
    debouncedSearch(search);
  }, [search]);

  useEffect(() => {
    setSearch(city || '');
  }, []);

  return (
    <Formik
      initialValues={{
        city: '',
      }}
    >
      <Form>
        <LabeledInput
          label={'Search by city'}
          name="city"
          component={FormikInput}
          placeholder="Search by city"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Form>
    </Formik>
  );
};

export default CityFilter;
