import type {
  Return,
  ShipFastCarrier,
  ShipFastOrder,
} from '@/swr/shipfast/types';
import dayjs from 'dayjs';
import {
  AmazonShippingIcon,
  BlueDartIcon,
  DtdcIcon,
} from 'velocity_engineering_ve_icons';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { useLocation } from 'react-router-dom';
import BlueDart from '@/ui-kit/assets/carriers/bluedart.svg?react';
import Ekart from '@/ui-kit/assets/carriers/ekart.svg?react';
import Shadowfax from '@/ui-kit/assets/carriers/shadowfax.svg?react';
import Ecom from '@/ui-kit/assets/carriers/ecom.svg?react';
import Delhivery from '@/ui-kit/assets/carriers/delhivery.svg?react';
import Blitz from '@/ui-kit/assets/carriers/blitz.svg?react';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

export const getPaymentStatus = (is_cod: boolean) => {
  if (is_cod) {
    return 'COD';
  } else {
    return 'Pre-Paid';
  }
};

export const getOrderStatus = (
  order_status: ShipFastOrder['attributes']['status'],
) => {
  const colorConfig = {
    pending: 'blue',
    invoiced: 'orange',
    new: 'blue',
    in_transit: 'blue',
    ready_to_ship: 'blue',
    ndr_raised: 'red',
    need_attention: 'red',
    rto_initiated: 'orange',
    rto_in_transit: 'orange',
    delivered: 'green',
    cancelled: 'red',
    rejected: 'red',
    ready_for_pickup: 'blue',
    out_for_delivery: 'blue',
    pickup_scheduled: 'blue',
    rto_delivered: 'green',
    return_pickup_scheduled: 'blue',
    return_in_transit: 'blue',
    return_delivered: 'green',
    return_cancelled: 'red',
    return_ndr_raised: 'red',
    return_need_attention: 'red',
  };

  return {
    color: colorConfig[order_status] || 'blue',
  };
};

export const getReturnStatusColor = (
  status: Return['attributes']['status'],
) => {
  const colorConfig = {
    return_requested: '#0F48E3',
    in_transit: '#0F48E3',
    pickup_scheduled: '#0F48E3',
    delivered: '#2BD65B',
  };

  return {
    color: colorConfig[status],
  };
};

export const getCarrierLogo = (carrierName: string) => {
  switch (carrierName?.toLowerCase()) {
    case 'amazon_shipping':
      return <AmazonShippingIcon />;
    case 'dtdc':
      return <DtdcIcon />;
    case 'bluedart':
      return <BlueDartIcon />;
  }
};

export const getPickupDate = (
  pickupDate: ShipFastCarrier['attributes']['pickup_date'],
) => {
  const date = dayjs(pickupDate);
  if (date.isToday()) {
    return 'Today';
  } else if (date.isTomorrow()) {
    return 'Tomorrow';
  } else {
    return date.format('DD MMM, YY');
  }
};

export const getDeliveryDate = (
  deliveryDate: ShipFastCarrier['attributes']['delivery_date'],
) => {
  const date = dayjs(deliveryDate);
  const diffFromToday = date.diff(dayjs(), 'day');
  return {
    deliveryRange:
      diffFromToday.toString() + '-' + (diffFromToday + 1).toString() + ' days',
    deliveryDate: date.format('DD MMM'),
  };
};

export const shipfastOrderStatusOptions = [
  { label: 'New', value: 'pending' },
  { label: 'In Transit', value: 'in_transit' },
  { label: 'Delivered', value: 'delivered' },
  { label: 'Needs attention', value: 'ndr_raised' },
  { label: 'RTO ', value: 'rto_initiated' },
  { label: 'Ready for pickup', value: 'ready_for_pickup' },
  { label: 'Fulfilled', value: 'externally_fulfilled' },
];

export const shipfastPaymentStatusOptions = [
  { label: 'Pre-Paid', value: 'paid' },
  { label: 'Cash On Delivery', value: 'unpaid' },
];

export const getDeliveryDateStatus = (
  actualDeliveryDate?: string,
  expectedDeliveryDate?: string,
) => {
  if (!actualDeliveryDate || !expectedDeliveryDate) {
    return { textColor: 'secondary', showExpectedDate: false };
  }

  const actualDate = dayjs(actualDeliveryDate);
  const expectedDate = dayjs(expectedDeliveryDate);

  if (
    actualDate.isSame(expectedDate, 'day') ||
    actualDate.isBefore(expectedDate)
  ) {
    return {
      textColor: 'success',
      showExpectedDate: actualDate.isBefore(expectedDate),
    };
  }

  return { textColor: 'danger', showExpectedDate: false };
};

export const useIsReturnsPage = () => {
  const { pathname } = useLocation();
  return {
    isReturnsPage: pathname.includes('/returns'),
  };
};

export const useIsOrderPage = () => {
  const { pathname } = useLocation();
  return {
    isOrderPage: pathname.includes('/orders'),
  };
};

export const useIsDiscrepanciesPage = () => {
  const { pathname } = useLocation();
  return {
    isDiscrepanciesPage: pathname.includes('/discrepancies'),
  };
};

// ['delhivery', 'ecom-express', 'shadowfax', 'ekart', 'bluedart'];

export const CourierIconMap = {
  bluedart: <BlueDart />,
  ekart: <Ekart />,
  shadowfax: <Shadowfax />,
  'ecom-express': <Ecom />,
  delhivery: <Delhivery />,
  blitz: <Blitz />,
} as const;

export type CourierName = keyof typeof CourierIconMap;

export const EXCHANGE_ITEMS_TEXT = 'This return contains exchange items';

export const BULK_ORDER_UPLOAD_SAMPLE_CSV =
  'https://shipfast-assets-prod.s3.ap-south-1.amazonaws.com/samples/bulk_order_upload.csv';
