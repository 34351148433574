/* eslint-disable @typescript-eslint/no-explicit-any, react-hooks/rules-of-hooks */
import { useProfile } from '@/swr/user';
import { API } from '@/utils/api_config';
import { fetcher } from '@/ve-utils/api_call';
import { makeQueryParams } from '@/ve-utils/common';
import useSWR, { mutate } from 'swr';
import { useCreditLine } from '../user';
import { useParams } from 'react-router-dom';
import {
  Applicant,
  Application,
  DocumentType,
  PaymentDetails,
  ShareholderListType,
  DocumentSignatureType,
  ShareholderType,
  FlywheelApplication,
  BankType,
} from './types';
import { LoanAccount } from '../user/types';
import { getFormattedShareholderList } from '@/pages/finance/utils/document_utils';
import { mutateSWR } from '@/swr/utils';
import { errorNotification } from '@/ui-kit';

const APPLICATION_LOADING_TEXT = 'Fetching Loan Application Details...';

export const createApplication = async ({ params }: any) => {
  const url = API.finance.createApplication;
  const body = params;

  const { data, ok }: any = await fetcher(url, {
    method: 'post',
    body: JSON.stringify(body),
  });

  return {
    application: data as FlywheelApplication,
    ok,
  };
};

const getApplicationParams = ({
  applicationIdParam,
  creditLine,
}: {
  applicationIdParam: string | undefined;
  creditLine: LoanAccount;
}) => {
  if (applicationIdParam) return { application_uuid: applicationIdParam };
  if (creditLine) return { application_uuid: creditLine?.application_uuid };
  return { application_type: 'finance' };
};

export function useApplication() {
  const { creditLine } = useCreditLine();
  const { application_id } = useParams();
  const params = makeQueryParams(
    getApplicationParams({ applicationIdParam: application_id, creditLine }),
    [],
  );

  const { data, error, isLoading, mutate } = useSWR([
    API.finance.getApplication,
    params,
  ]);
  const result = data?.data;
  const documents: any[] = result?.documents;
  const updated_documents = documents?.map((doc) =>
    doc.service_url && doc.documents.length === 0
      ? {
          ...doc,
          documents: [
            {
              service_url: doc.service_url,
              file_name: doc.file_name,
              signed_id: doc.signed_id,
            },
          ],
        }
      : doc,
  );
  return {
    application: (result
      ? { ...result, documents: updated_documents }
      : result) as Application,
    isLoading,
    error,
    reloadApplication: mutate,
    applicationLoadingText: APPLICATION_LOADING_TEXT,
  };
}

export const getApplicationUsingFetcher = async ({
  application_uuid,
}: {
  application_uuid: string;
}) => {
  const params = makeQueryParams(
    {
      application_uuid,
    },
    [],
  );

  const url = `${API.finance.getApplication}?${params}`;
  const { ok, data } = await fetcher(url, { method: 'get' });

  return { ok, application: data?.data };
};

export const useDocumentsList = ({
  uuid,
  stage = 'credit_underwriting',
  company_type = null,
}: {
  uuid: string;
  stage: string;
  company_type?: string | null;
}) => {
  const url = API.finance.getDocuments(uuid);
  const queryParams = makeQueryParams({ stage, company_type }, []);
  const { data, error, isLoading } = useSWR([url, queryParams]);

  return {
    documentList: data?.data,
    isLoading,
    error,
  };
};

export const useFetchDocumentSignatures = ({
  application_uuid,
  document_uuid,
}: {
  application_uuid: string;
  document_uuid: string;
}) => {
  const url = API.finance.getDocumentSignatures(
    application_uuid,
    document_uuid,
  );
  const { data, error, isLoading } = useSWR(url);

  return {
    signatures: data?.data as DocumentSignatureType[],
    error,
    isLoading,
  };
};

export const useFetchBanks = async ({
  name,
  finbit_enabled,
}: {
  name?: string;
  finbit_enabled?: boolean;
}) => {
  const params = makeQueryParams(
    {
      name,
      finbit_enabled,
    },
    [],
  );
  const url = `${API.finance.getBanks}?${params}`;

  const { data, ok }: any = await fetcher(url, {
    method: 'get',
  });

  return {
    banks: data?.data as BankType[],
    ok,
  };
};

export function useShareholderList({
  combined_document,
  kyc_document,
  addendum_document,
  applicationId,
}: ShareholderListType) {
  const defaultData = { signatures: undefined, isLoading: false };

  const {
    signatures: agreement_signatures = [],
    isLoading: isFetchingCombinedSignatures,
  } = combined_document
    ? useFetchDocumentSignatures({
        application_uuid: applicationId,
        document_uuid: combined_document.uuid,
      })
    : defaultData;

  const {
    signatures: kyc_signatures = [],
    isLoading: isFetchingKycSignatures,
  } = kyc_document
    ? useFetchDocumentSignatures({
        application_uuid: applicationId,
        document_uuid: kyc_document.uuid,
      })
    : defaultData;

  const {
    signatures: addendum_signatures = [],
    isLoading: isFetchingAddendumSignatures,
  } = addendum_document
    ? useFetchDocumentSignatures({
        application_uuid: applicationId,
        document_uuid: addendum_document.uuid,
      })
    : defaultData;

  const isLoading =
    isFetchingCombinedSignatures ||
    isFetchingKycSignatures ||
    isFetchingAddendumSignatures;

  const { shareholderList, isSigned } = getFormattedShareholderList(
    agreement_signatures,
    kyc_signatures,
    addendum_signatures,
  );

  return {
    isLoading,
    shareholderList: shareholderList as ShareholderType[],
    isSigned,
  };
}

export const useDocumentTypes = () => {
  const { data, error, isLoading } = useSWR(API.finance.getDocumentDetails);

  return {
    documentTypes: data?.data as DocumentType[],
    isLoading,
    error,
  };
};

export const useApplicant = () => {
  const { application } = useApplication();
  const application_uuid = application?.uuid;
  const params = makeQueryParams(
    {
      application_uuid,
    },
    [],
  );
  const { data, error, isLoading } = useSWR(
    application_uuid ? [API.finance.getApplicant, params] : undefined,
  );

  return {
    applicant: data?.data as Applicant,
    isLoading: isLoading,
    error,
  };
};

export const useOfferDetails = () => {
  const { application } = useApplication();
  const application_type_uuid = application?.type_details?.uuid;
  const { data, error, isLoading } = useSWR(
    application_type_uuid
      ? API.finance.getOfferDetails(application_type_uuid)
      : undefined,
  );

  return {
    offerDetails: data?.data,
    isLoading,
    error,
  };
};

export const uploadFile = async ({
  formData,
  application_uuid,
}: {
  formData: FormData;
  application_uuid: string;
}) => {
  const url = API.finance.uploadDocument(application_uuid);
  const { ok, data } = await fetcher(url, {
    method: 'upload',
    body: formData,
  });
  return { ok, data };
};

export const deleteFile = async ({
  uuid,
  signed_id,
  application_uuid,
}: {
  uuid: string;
  signed_id: string;
  application_uuid: string;
}) => {
  const params = makeQueryParams(
    {
      id: uuid,
      signed_id: signed_id,
      application_uuid,
    },
    [],
  );
  const url = API.finance.deleteDocument + `?${params}`;
  const { ok, data } = await fetcher(url, {
    method: 'delete',
  });
  return {
    ok,
    data,
  };
};

export const uploadMultipleFiles = async ({
  formData,
  borrower_id,
  signatory_id,
  application_uuid,
}: {
  formData: FormData;
  borrower_id: string;
  signatory_id: string;
  application_uuid: string;
}) => {
  const params = makeQueryParams(
    {
      application_uuid,
      borrower_id,
      signatory_uuid: signatory_id,
    },
    [],
  );
  const url = API.finance.uploadMultipleDocuments + `?${params}`;
  const { ok, data } = await fetcher(url, {
    method: 'upload',
    body: formData,
  });
  return { ok, data };
};

export const setFrontendState = async (
  state: any,
  application_uuid: string,
) => {
  return await updateApplication({
    updateParams: {
      frontend_data: JSON.stringify(state),
    },
    application_uuid,
  });
};

export const updateApplication = async ({
  updateParams,
  application_uuid,
}: {
  updateParams: any;
  application_uuid: string;
}) => {
  const url = API.finance.updateApplication;
  const body = JSON.stringify({
    application: updateParams,
    application_uuid,
  });
  const { ok, data } = await fetcher(url, {
    method: 'put',
    body,
  });
  return { ok, data };
};

export const updateApplicationData = async ({
  contact_data,
  business_data,
  application_uuid,
}: {
  contact_data: any;
  business_data: any;
  application_uuid: string;
}) => {
  const url = API.finance.updateApplicationData(application_uuid);
  const body = JSON.stringify({ contact_data, business_data });
  const { ok, data } = await fetcher(url, {
    method: 'put',
    body,
  });
  return { ok, data };
};

export const useInvites = () => {
  const { application } = useApplication();
  const application_uuid = application?.uuid;
  const { data, error, isLoading } = useSWR(
    application_uuid ? API.finance.getInvites(application_uuid) : undefined,
  );

  return {
    invites: data?.data,
    isLoading,
    error,
  };
};

export const usePGIntegrations = () => {
  const { application } = useApplication();
  const { creditLine } = useCreditLine();
  const { type_details } = application || {};
  const nbfc_id = type_details?.nbfc_id || creditLine?.lender?.id || '';

  const { data, error, isLoading } = useSWR(
    API.finance.getPGIntegrations(nbfc_id.toString()),
  );

  return {
    paymentDetails: data?.data as PaymentDetails,
    isLoading,
    error,
  };
};

export const useConnectionStatus = ({
  paymentGateway,
}: {
  paymentGateway: any;
}) => {
  const { application } = useApplication();
  const { profile } = useProfile();
  const { creditLine } = useCreditLine();
  const { type_details } = application || {};
  const nbfc_id = type_details?.nbfc_id || creditLine?.lender?.id;
  const params = makeQueryParams(
    {
      gateway: paymentGateway,
      company_uuid: profile.company.uuid,
      nbfc_id,
    },
    [],
  );
  const { data, error, isLoading } = useSWR(
    API.finance.getConnectionStatus + `?${params}`,
  );

  return {
    connectionStatus: data?.integration_status,
    isLoading,
    error: error,
  };
};

export const invitePerson = async ({
  details,
  application_uuid,
}: {
  details: any;
  application_uuid: string;
}) => {
  const url = API.finance.invitePerson;
  const body = JSON.stringify({
    ...details,
    application_uuid,
  });
  const { ok, data } = await fetcher(url, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const addPGIntegrations = async ({ payload }: { payload: any }) => {
  const url = API.finance.addPGIntegrations;
  const body = JSON.stringify({
    payload,
  });
  const { ok, data } = await fetcher(url, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const updateShareCode = async ({
  id,
  share_code,
  application_uuid,
}: {
  id: string;
  share_code: string;
  application_uuid: string;
}) => {
  const url = API.finance.addPGIntegrations;
  const params = makeQueryParams(
    {
      id,
      share_code,
      application_uuid,
    },
    [],
  );
  const { ok, data } = await fetcher(url + `?${params}`, {
    method: 'put',
  });
  return { ok, data };
};

export const updateApplicationState = async (
  event: string,
  applicationId: string,
) => {
  const url = API.finance.updateFinanceApplicationState(applicationId);

  const { data, ok }: any = await fetcher(url, {
    method: 'put',
    body: JSON.stringify({ event }),
  });

  if (ok && data.uuid) {
    const fetchApplicationDetails = API.finance.getApplication;
    await mutateSWR(fetchApplicationDetails, mutate);
  } else {
    let message = data?.message ?? 'Something went wrong!';
    if (data?.details?.length > 0) {
      message = data.details[0].issue ?? message;
    }
    errorNotification({ message });
  }

  return { data, ok };
};
