import useSWR, { mutate } from 'swr';

import { API } from '@/utils/api_config';
import { useCreditLine } from '../user';
import {
  ApplicationIntegration,
  Integration,
  InternalReports,
  LoanAccountConnections,
  ConsentType,
} from './types';
import { convertArray2Map } from '@/ve-utils/reducer_utils';
import { fetcher } from '@/ve-utils/api_call';
import { useApplication } from '../apply';
import { mutateSWR } from '@/swr/utils';
import { errorNotification } from '@/ui-kit';
import { makeQueryParams } from '@/ve-utils/common';
import useSWRImmutable from 'swr/immutable';

const INTERNAL_REPORTS_LOADING_TEXT = 'Fetching Reports...';

export function useLoanAccountConnections() {
  const { activeLoanAccountUUID } = useCreditLine();
  const url = () =>
    activeLoanAccountUUID
      ? API.finance.loanAccountConnection(activeLoanAccountUUID)
      : undefined;

  const { data, error, isLoading } = useSWRImmutable(url);

  return {
    loanAccountConnections: (data || {}) as LoanAccountConnections,
    isLoading: isLoading,
    error,
  };
}

export const useApplicationIntegrations = (
  applicationId: string,
  stage: string,
) => {
  const { data, error, isLoading } = useSWR(
    `${API.root.getApplicationIntegrations(applicationId)}?stage=${stage}`,
  );
  const applicationIntegrations = (data?.data ||
    []) as ApplicationIntegration[];

  return {
    applicationIntegrations,
    isLoading,
    error,
  };
};

export const useIntegrations = () => {
  const { data, error, isLoading, mutate } = useSWR(API.root.getIntegrations);
  const integrations = (data?.data || []) as Integration[];
  const { ids, map } = convertArray2Map(integrations);
  const isShopifyConnected = !!integrations.find(
    (el) => el.integration_name === 'SHOPIFY',
  );
  return {
    integrations,
    integrationsMap: map,
    integrationKeys: ids as string[],
    isLoading,
    error,
    reloadIntegrations: mutate,
    isShopifyConnected,
  };
};

type DiscardIntegrationProps = {
  integrationId: string;
  discardReason: string;
};

export const discardIntegration = async ({
  integrationId,
  discardReason,
}: DiscardIntegrationProps) => {
  const { ok } = await fetcher(
    API.root.discardIntegration(integrationId, discardReason),
    {
      method: 'put',
    },
  );
  if (ok) {
    await mutateSWR(API.root.getIntegrations, mutate);
  }
  return { ok };
};

export const useIntegrationsRejection = () => {
  const { application } = useApplication();
  const application_uuid = application?.uuid;
  const { data, error, isLoading } = useSWR(
    application_uuid
      ? API.finance.getIntegrationsRejection(application_uuid)
      : undefined,
  );

  return {
    rejection_data: data?.rejection_data || [],
    rejected: data?.rejection_data?.filter(
      (reject) =>
        (reject.integration_name === 'Experian' &&
          reject.event_type === 'hard_reject') ||
        reject.integration_name !== 'Experian',
    ),
    isLoading,
    error,
  };
};

export const verifyFlipkart = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const body = JSON.stringify({
    username,
    password,
  });
  const { ok, data } = await fetcher(API.finance.verifyFlipkart, {
    method: 'post',
    body,
  });
  const flData = data?.data ? data?.data : { success: false };
  const formStep = data?.data ? 2 : 1;
  return { ok, flipKartData: flData, formStep: formStep };
};

export const requestGstOtp = async (gst_no: string, gstUserName: string) => {
  const requestGstOtpUrl = API.finance.requestGstOtp;
  const data = {
    gst_no,
    username: gstUserName,
  };
  const response = await fetcher(requestGstOtpUrl, {
    method: 'post',
    body: JSON.stringify(data),
  });
  return response;
};

export const validateGstOtp = async (data: { otp: string; id: string }) => {
  const validateGstOtpUrl = API.finance.validateGstOtp;
  const response = await fetcher(validateGstOtpUrl, {
    method: 'post',
    body: JSON.stringify(data),
  });
  return response;
};

export const createFlipkartIntegration = async ({
  payload,
}: {
  payload: any;
}) => {
  const body = JSON.stringify(payload);
  const { ok, data } = await fetcher(API.finance.createFlipkartIntegration, {
    method: 'post',
    body,
  });
  const flData = data?.data ? data?.data : { success: false };
  const formStep = data?.data ? 2 : 1;
  return { ok, flipKartData: flData, formStep: formStep };
};

export const createIntegration = async ({ values }: { values: any }) => {
  const body = JSON.stringify(values);
  const { ok, data } = await fetcher(API.finance.createIntegration, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const updatePanIntegration = async ({
  pan,
  dob,
}: {
  pan: string;
  dob?: string;
}) => {
  const body = JSON.stringify({ pan, dob });
  const { ok, data } = await fetcher(API.finance.updatePANIntegration, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const updateGSTIntegration = async ({
  gst_no,
  username,
  password,
}: {
  gst_no: string;
  username: string;
  password: string;
}) => {
  const body = JSON.stringify({ gst_no, username, password });
  const { ok, data } = await fetcher(API.finance.updateGSTIntegration, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const selectIntegrationAccount = async ({
  payload,
  integrationId,
}: {
  payload: any;
  integrationId: string;
}) => {
  const body = JSON.stringify(payload);
  const { ok, data } = await fetcher(
    API.finance.selectIntegrationAccount(integrationId),
    { method: 'post', body },
  );
  return { ok, data };
};

export const useRevenueSources = () => {
  const { data, error, isLoading } = useSWR(API.finance.getRevenueSources);

  return {
    revenueSources: data?.data,
    isLoading,
    error,
  };
};

export const createMeeshoIntegration = async ({ values }: { values: any }) => {
  const body = JSON.stringify(values);
  const { ok, data } = await fetcher(
    API.finance.createIntegration + '/meesho_integration',
    { method: 'post', body },
  );
  return { ok, data };
};

export const useFetchInternalReports = () => {
  const url = API.root.getInternalReports;

  const { data, error, isLoading, mutate } = useSWRImmutable(url);

  return {
    internalReports: (data?.data || []) as InternalReports[],
    reloadInternalReports: mutate,
    isLoading,
    error,
    reportsLoadingText: INTERNAL_REPORTS_LOADING_TEXT,
  };
};

export const verifyDirectorMobile = async (payload: any) => {
  const body = JSON.stringify(payload);
  const { ok, data } = await fetcher(API.finance.directorVerifyMobile, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const verifyDirectorMaskedMobile = async (payload: any) => {
  const body = JSON.stringify(payload);
  const { ok, data } = await fetcher(API.finance.directorAuthenticateMobile, {
    method: 'post',
    body,
  });
  return { ok, data };
};

export const useFetchConsents = ({
  consent_type,
  status,
}: {
  consent_type?: string;
  status?: string;
}) => {
  const url = API.finance.consents;
  const params = makeQueryParams({ consent_type, status }, []);

  const { data, error, isLoading, mutate } = useSWR([url, params]);
  const consents = data?.data as ConsentType[];

  return {
    consents,
    error,
    isLoading,
    reloadConsents: mutate,
  };
};

export const createConsent = async (payload: any) => {
  const url = API.finance.consents;

  const { data, ok } = await fetcher(url, {
    method: 'post',
    body: JSON.stringify(payload),
  });

  if (!ok)
    errorNotification({
      description: data?.errors?.[0] ?? 'Something went wrong',
    });

  return { data: data?.data, ok };
};
