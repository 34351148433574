/**
 * Add all typography components for shipping here
 */

import { Typography } from 'antd';
import classNames from 'classnames';
import type { TextProps } from 'antd/lib/typography/Text';
import { NewTag } from '@/ui-kit/tags/shipfast';

import styles from './typography.module.less';

const { Text } = Typography;

interface ColumnTextHeader extends TextProps {
  isHoverable?: boolean;
  isIndexColumn?: boolean;
}

export const ColumnTextHeader = ({
  children,
  isHoverable = false,
  isIndexColumn = false,
  className,
  ...restProps
}: ColumnTextHeader) => {
  return (
    <Text
      className={classNames(
        styles.columnTextHeader,
        isHoverable ? styles.isColumnTextHeaderUnderlined : '',
        isHoverable ? styles.isTrigger : '',
        isIndexColumn ? styles.indexColumn : '',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};

interface ColumnTextDesc extends TextProps {
  type?: 'secondary';
}

export const ColumnTextDesc = ({
  children,
  type,
  className,
  ...restProps
}: ColumnTextDesc) => {
  const isSecondary = type === 'secondary';
  return (
    <Text
      className={classNames(
        styles.columnTextDesc,
        isSecondary ? styles.isSecondaryText : '',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  );
};

export const ColumnTextDescHeader = ({
  children,
  ...restProps
}: ColumnTextDesc) => {
  return (
    <ColumnTextDesc
      type="secondary"
      {...restProps}
      className={styles.columnTextDescHeader}
    >
      {children}
    </ColumnTextDesc>
  );
};

export const TagDisplay = ({ text }: { text: string }) => {
  return (
    <NewTag
      text={text}
      IconComponent={<></>}
      className={styles.tagDisplay}
      textClassNames={styles.tagDisplayText}
      showEllipsis={false}
    />
  );
};
