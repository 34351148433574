import { API } from '@/utils/api_config';
import useSWR, { State } from 'swr';
import useSWRImmutable from 'swr/immutable';
import type {
  ManualReturnItem,
  Package,
  RechargeWalletResponse,
  Return,
  ReturnCharges,
  ReturnStatus,
  ReturnStatusSummary,
  ShipFastCarrier,
  ShipfastCarrierMode,
  ShipfastChannel,
  ShipfastDashboardDetails,
  ShipFastOrder,
  ShipfastOrderEditType,
  ShipfastOrderMode,
  ShipfastOrderStatus,
  ShipfastPricing,
  ShipfastProfile,
  ShipfastStatusSummary,
  ShipFastWarehousePickup,
  ShipmentsData,
  ShippingRule,
  ShippingRuleCarrier,
  ShippingRuleCarrierSummary,
  StateType,
} from './types';
import { isProduction, makeQueryParams } from '@/ve-utils/common';
import type { Meta } from '@/pages/payments/swr/invoices/types';
import { fetcher } from '@/ve-utils/api_call';
import useSWRInfinite from 'swr/infinite';
import { checkHasMore, isInfiniteLoading, swrInfiniteConfig } from '../utils';
import { useQuery } from '@/hooks/useQuery';
import {
  CalendarOutlined,
  RollbackOutlined,
  UnorderedListOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
  CheckDoubleLineIcon,
  SupportLineIcon,
  Upload2LineIcon,
} from 'velocity_engineering_ve_icons';
import ReturnRequested from '@/ui-kit/assets/ReturnRequested.svg?react';
import {
  CourierIconMap,
  useIsDiscrepanciesPage,
  useIsOrderPage,
  useIsReturnsPage,
} from '@/pages/shipfast/pages/orders/utils';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  useShipfastDateFilters,
  useShipfastOrderFilters,
} from '@/pages/shipfast/pages/orders/components/shipfast_order_filters/utils';
import type { SurfaceType } from '@/pages/shipfast/pages/orders/components/shipping_drawer/solo_shipping';
import type { SortOption } from '@/pages/shipfast/pages/orders/components/shipping_drawer/solo_shipping/solo_shipping_courier_list';
import { useIntegrations } from '@/pages/finance/swr/integrations';

import type { DownloadInfo } from './billing/types';

export const useShipfastOrderQueryParams = () => {
  const { query } = useQuery();
  const { order_status, sort_by, sort_order, search } = query;
  const {
    selectedWarehouseIds,
    selectedChannels,
    selectedTags,
    is_cod,
    city,
    express,
  } = useShipfastOrderFilters();
  const { from, to } = useShipfastDateFilters();
  const queryParams = {
    status: order_status !== 'all' ? order_status : undefined,
    search: search ? encodeURIComponent(search) : undefined,
    is_cod,
    warehouse_ids: selectedWarehouseIds.length > 0 ? selectedWarehouseIds : [],
    channel:
      selectedChannels.length > 0
        ? JSON.stringify(selectedChannels)
        : undefined,
    tags: selectedTags.length > 0 ? selectedTags : [],
    sort_by,
    sort_order,
    start_time: from,
    end_time: to,
    city: city?.toLowerCase(),
    express,
  };
  return {
    params: queryParams,
  };
};

export const useShipfastOrders = () => {
  const url = API.shipfast.getOrders;
  const { reloadStatusSummary } = useShipfastStatusSummary();

  const { params } = useShipfastOrderQueryParams();

  const { data, mutate, size, setSize, isValidating, isLoading, error } =
    useSWRInfinite((index) => {
      const queryParams = makeQueryParams({
        ...params,
        pageNumber: (index + 1).toString(),
      });
      return [url, queryParams];
    }, swrInfiniteConfig);
  const meta = (data?.at(-1)?.meta || {}) as Meta;
  const shipfastOrders: ShipFastOrder[] = (data || [])?.reduce((acc, item) => {
    return [...acc, ...item.data];
  }, []);

  return {
    shipfastOrders,
    meta,
    reloadShipfastOrders: () => {
      mutate();
      reloadStatusSummary(); //  also reloads the status summary
    },
    size,
    setSize,
    isValidating,
    isLoading: isInfiniteLoading({ size, data, isLoading }),
    error,
    hasMore: checkHasMore(meta),
  };
};

export const useDeliveredOrders = (search?: string) => {
  const url = API.shipfast.getDeliveredOrders;
  const queryParams = makeQueryParams({
    search: search ? encodeURIComponent(search) : undefined,
    status: 'delivered',
    perPage: '10',
  });
  //   const { data, error, isLoading } = useSWR(search ? [url, queryParams] : null);
  const { data, error, isLoading } = useSWR([url, queryParams]);
  const deliveredOrders = (data?.data || []) as ShipFastOrder[];

  return {
    deliveredOrders,
    error,
    isLoading,
  };
};

export const useDeliveredOrder = (orderId: string) => {
  const { deliveredOrders } = useDeliveredOrders();
  const deliveredOrder = deliveredOrders.find((item) => item.id === orderId);

  return {
    deliveredOrder,
  };
};

export const useSelectedShipfastOrders = () => {
  const { query } = useQuery();
  const { selected_order_ids } = query;
  const selectedShipfastOrdersId = selected_order_ids
    ? selected_order_ids?.split(',')
    : [];
  const { shipfastOrders } = useShipfastOrders();
  const selectedShipfastOrders = shipfastOrders.filter((item) =>
    selectedShipfastOrdersId.includes(item.id),
  );
  const totalItemsCost = selectedShipfastOrders.reduce((acc, item) => {
    return acc + Number(item.attributes.total_price);
  }, 0);

  return {
    totalItemsCost,
    selectedShipfastOrders,
    selectedShipfastOrdersId,
    selected_order_ids,
  };
};

export const getItemCost = (
  item: ShipFastOrder['attributes']['items'][number],
) => {
  return Number(item.price) * Number(item.quantity);
};

export const getTotalItemsCost = (
  items: ShipFastOrder['attributes']['items'],
) => {
  const totalItemCost = items?.reduce((acc, item) => {
    return acc + getItemCost(item);
  }, 0) as number;
  return totalItemCost;
};

export const useShipfastOrder = () => {
  const { query } = useQuery();

  const { orderId, mode = 'view', editType } = query;
  const url = orderId ? API.shipfast.getOrder(orderId) : null;
  const { reloadShipfastOrders } = useShipfastOrders();
  const { data, error, isLoading, mutate } = useSWR(url);
  const isModeEdit = mode === 'edit';
  const shipfastOrder = (data?.data || {}) as ShipFastOrder;
  const items = shipfastOrder?.attributes?.items;
  const totalItemCost = getTotalItemsCost(items);
  return {
    shipfastOrder: (data?.data || {}) as ShipFastOrder,
    error,
    isLoading: orderId ? isLoading : true,
    reloadShipfastOrder: () => {
      mutate();
      reloadShipfastOrders();
    },
    mode: mode as ShipfastOrderMode,
    editType: editType as ShipfastOrderEditType,
    isModeEdit,
    totalItemCost,
  };
};

export const useShipfastOrderDetails = (orderId: string) => {
  const url = orderId ? API.shipfast.getOrderDetails(orderId) : null;
  const { data, error, isLoading, mutate } = useSWR(url);
  const shipfastOrder = (data?.data || {}) as ShipFastOrder;
  return {
    shipfastOrder,
    error,
    isLoading,
  };
};

export const useShipfastCarriers = () => {
  const url = API.shipfast.getCarriers;
  const { data, error, isLoading } = useSWRImmutable(url);
  const shipfastCarriers = (data?.data || []) as ShipFastCarrier[];
  const shipfastCarriersCountMap = shipfastCarriers?.reduce(
    (acc, item) => {
      acc[item.attributes.service_level] =
        acc[item.attributes.service_level] + 1;
      return acc;
    },
    {
      all: shipfastCarriers.length,
      air: 0,
      surface: 0,
    } as Record<ShipFastCarrier['attributes']['service_level'], number>,
  );

  return {
    shipfastCarriers,
    shipfastCarriersCountMap,
    error,
    isLoading,
  };
};

type CarrierWithPricing = {
  id: string;
  carrier: ShipFastCarrier;
  pricing: ShipfastPricing;
  service_level: ShipFastCarrier['attributes']['service_level'];
};

export const useShipfastCarrierOrderWise = (orderId?: string) => {
  const { query } = useQuery();
  const orderIdVal = orderId || query.orderId;
  const { pricing, reloadPricing, isLoading, error } = usePricing(orderIdVal);
  const { shipfastCarriers } = useShipfastCarriers();

  const finalCourierList: CarrierWithPricing[] = [];

  const surfaceCarriers: CarrierWithPricing[] = [];
  const airCarriers: CarrierWithPricing[] = [];

  pricing.forEach((item) => {
    const carrier = shipfastCarriers.find(
      (carrier) => carrier.id === item.carrier,
    );
    const roadChargesExist = carrier?.attributes?.service_level === 'road';

    if (roadChargesExist) {
      const data = {
        id: item.carrier + carrier?.attributes?.service_level + item.id,
        carrier: carrier!,
        pricing: item,
        service_level: 'surface',
      };
      surfaceCarriers.push(data);
      finalCourierList.push(data);
    }

    const airChargesExist = ['air', 'sdd', 'ndd'].includes(
      carrier?.attributes?.service_level,
    );

    if (airChargesExist) {
      const data = {
        id: item.carrier + carrier?.attributes?.service_level + item.id,
        carrier: carrier!,
        pricing: item,
        service_level: 'air',
      };
      airCarriers.push(data);
      finalCourierList.push(data);
    }
  });
  return {
    finalCourierList: finalCourierList,
    surfaceCarriers,
    airCarriers,
    shipfastCarriersCountMap: {
      surface: surfaceCarriers.length,
      air: airCarriers.length,
      all: finalCourierList.length,
    },
    reloadPricing,
    isLoading,
    error,
  };
};

export const useShipfastCarrier = (carrierId: string) => {
  const { shipfastCarriers } = useShipfastCarriers();
  const carrier = shipfastCarriers.find((item) => item.id === carrierId);

  return {
    carrier,
  };
};

export const createShipment = ({
  carrierId,
  shipmentId,
  mode,
  packageId,
}: {
  carrierId: string;
  shipmentId: string;
  mode: string;
  packageId: string;
}) => {
  const url = API.shipfast.createShipment(shipmentId);
  const body = JSON.stringify({
    carrier_id: carrierId,
    mode,
    package_id: packageId,
  });
  return fetcher(url, {
    method: 'post',
    body,
  });
};

export const createSelfFulfillmentShipment = ({
  orderId,
  tracking_number,
  courier_partner,
  phone_number,
}: {
  orderId: string;
  tracking_number: string;
  courier_partner: string;
  phone_number: string;
}) => {
  const url = API.shipfast.selfFulfillment(orderId);
  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({
      tracking_number,
      courier_partner,
      phone_number,
    }),
  });
};

export const patchShippingAddress = ({
  orderId,
  shipping_address,
}: {
  orderId: string;
  shipping_address: ShipFastOrder['attributes']['shipping_address'];
}) => {
  const url = API.shipfast.patchOrder(orderId);
  return fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      order: { shipping_address },
    }),
  });
};

export const patchReturnShippingAddress = ({
  returnId,
  shipping_address,
}: {
  returnId: string;
  shipping_address: ShipFastOrder['attributes']['shipping_address'];
}) => {
  const url = API.shipfast.patchReturn(returnId);
  return fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      return: { shipping_address },
    }),
  });
};

export const patchWarehousePickupId = async ({
  orderId,
  warehouse_id,
}: {
  orderId: string;
  warehouse_id: string;
}) => {
  const url = API.shipfast.patchOrder(orderId);
  return await fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      order: { warehouse_id },
    }),
  });
};

export const cancelOrder = ({ orderId }: { orderId: string }) => {
  const url = API.shipfast.cancelOrder(orderId);
  return fetcher(url, {
    method: 'post',
  });
};

export const cancelShipment = ({ shipmentId }: { shipmentId: string }) => {
  const url = API.shipfast.cancelShipment(shipmentId);
  return fetcher(url, {
    method: 'post',
  });
};

export const useShipfastWarehousePickupList = () => {
  const url = API.shipfast.getWarehousePickupList;
  const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(url);
  const shipfastWarehousePickupList = (data?.data ||
    []) as ShipFastWarehousePickup[];
  const isWarehouseAdded = shipfastWarehousePickupList.length > 0;

  return {
    shipfastWarehousePickupList,
    isWarehouseAdded,
    error,
    isLoading,
    reloadWarehousePickupList: mutate,
    isValidating,
  };
};

export const getWareHoouseFullAddress = (
  warehouse: ShipFastWarehousePickup,
) => {
  const { pickup_address } = warehouse.attributes;
  const { street_address, city, state, zip, country } = pickup_address;
  return `${street_address}, ${city}, ${state}, ${zip}, ${country}`;
};

export const useShipfastWarehouseDetails = (id: string) => {
  const { shipfastWarehousePickupList } = useShipfastWarehousePickupList();
  const warehouseDetails = shipfastWarehousePickupList.find(
    (item) => item.id === id,
  );

  return {
    warehouseDetails,
  };
};

export const patchShipfastOrder = ({
  orderId,
  values,
}: {
  orderId: string;
  values: any;
}) => {
  const url = API.shipfast.patchOrder(orderId);
  return fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      order: values,
    }),
  });
};

export const initiateRto = ({ shipmentId }: { shipmentId: string }) => {
  const url = API.shipfast.initiateRto(shipmentId);
  return fetcher(url, {
    method: 'post',
  });
};

export const useShipfastStatusSummary = () => {
  const { query } = useQuery();
  const { search } = query;
  const url = API.shipfast.getStatusSummary;
  const {
    selectedPaymentType,
    selectedWarehouseIds,
    selectedChannels,
    selectedTags,
    is_cod,
    city,
  } = useShipfastOrderFilters();
  const { from, to } = useShipfastDateFilters();
  const queryParams = makeQueryParams({
    search: search ? encodeURIComponent(search) : undefined,
    payment_status: selectedPaymentType,
    warehouse_ids: selectedWarehouseIds.length > 0 ? selectedWarehouseIds : [],
    channel:
      selectedChannels.length > 0
        ? JSON.stringify(selectedChannels)
        : undefined,
    tags: selectedTags.length > 0 ? selectedTags : [],
    start_time: from,
    end_time: to,
    is_cod,
    city,
  });

  const { data, error, isLoading, mutate } = useSWRImmutable([
    url,
    queryParams,
  ]);

  const statusSummaryList = (data?.data || []) as ShipfastStatusSummary[];

  const statusSummaryMap = statusSummaryList?.reduce(
    (acc, item) => {
      acc[item.status] = item.count;
      return acc;
    },
    {} as Record<ShipfastOrderStatus, number>,
  );

  const TAB_ITEMS = [
    {
      key: 'pending',
      label: 'New',
      count: statusSummaryMap['pending'] || 0,
      icon: <PlusCircleOutlined />,
    },
    {
      key: 'ready_to_ship',
      label: 'Ready to ship',
      count: statusSummaryMap['ready_to_ship'] || 0,
      icon: <Upload2LineIcon />,
    },
    {
      key: 'in_transit',
      label: 'In-transit',
      count: statusSummaryMap['in_transit'] || 0,
      icon: <CalendarOutlined />,
    },
    {
      key: 'delivered',
      label: 'Delivered',
      count: statusSummaryMap['delivered'] || 0,
      icon: <CheckDoubleLineIcon />,
    },
    {
      key: 'ndr_raised',
      label: 'Needs attention',
      count: statusSummaryMap['ndr_raised'] || 0,
      icon: <WarningOutlined />,
    },
    {
      key: 'rto_initiated',
      label: 'RTO',
      count: statusSummaryMap['rto_initiated'] || 0,
      icon: <RollbackOutlined />,
    },
    {
      key: 'all',
      label: 'All',
      count: statusSummaryMap['all'] || 0,
      icon: <UnorderedListOutlined />,
    },
  ];
  return {
    statusSummary: data?.data || {},
    error,
    isLoading,
    TAB_ITEMS,
    reloadStatusSummary: mutate,
  };
};

export const bulkCreateShipments = ({
  shipmentsData,
}: {
  shipmentsData: ShipmentsData[];
}) => {
  const url = API.shipfast.bulkCreateShipments;
  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({ shipments: shipmentsData }),
  });
};

export const createReturn = ({
  orderId,
  return_reason,
}: {
  orderId: string;
  return_reason: string;
}) => {
  const url = API.shipfast.createReturn(orderId);
  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({ return_reason }),
  });
};

export const useShipfastReturns = () => {
  const { isReturnsPage } = useIsReturnsPage();
  const { query } = useQuery();
  const { search, status } = query;
  const url = isReturnsPage ? API.shipfast.getReturns : null;
  const { reloadStatusSummary } = useReturnStatusSummary();
  const { data, mutate, size, setSize, isValidating, isLoading, error } =
    useSWRInfinite((index) => {
      const queryParams = makeQueryParams({
        pageNumber: (index + 1).toString(),
        status: status !== 'all' ? status : undefined,
        search,
      });
      return [url, queryParams];
    }, swrInfiniteConfig);
  const processedData = data?.some((page) => Object.keys(page).length > 0)
    ? data
    : undefined;
  const meta = (data?.at(-1)?.meta || {}) as Meta;

  const returns: Return[] = (processedData ? data : [])?.reduce((acc, item) => {
    return [...acc, ...item.data];
  }, []);

  return {
    returns,
    meta,
    reloadReturns: () => {
      mutate();
      reloadStatusSummary();
    },
    size,
    setSize,
    isValidating,
    isLoading: isInfiniteLoading({ size, data, isLoading }),
    error,
    hasMore: checkHasMore(meta),
  };
};

export const useReturnStatusSummary = () => {
  const { isReturnsPage } = useIsReturnsPage();
  const url = isReturnsPage ? API.shipfast.getReturnsStatusSummary : null;
  const { data, error, isLoading, mutate } = useSWRImmutable(url);
  const statusSummaryList = (data?.data || []) as ReturnStatusSummary[];

  const statusSummaryMap = statusSummaryList?.reduce(
    (acc, item) => {
      acc[item.status] = item.count;
      return acc;
    },
    {} as Record<ReturnStatus, number>,
  );

  const TAB_ITEMS = [
    {
      key: 'return_requested',
      label: 'Return requests',
      count: statusSummaryMap['return_requested'] || 0,
      icon: <ReturnRequested />,
    },
    {
      key: 'pickup_scheduled',
      label: 'Pickup scheduled',
      count: statusSummaryMap['pickup_scheduled'] || 0,
      icon: <Upload2LineIcon />,
    },
    {
      key: 'in_transit',
      label: 'In-transit',
      count: statusSummaryMap['in_transit'] || 0,
      icon: <CalendarOutlined />,
    },
    {
      key: 'return_received',
      label: 'Return received',
      count: statusSummaryMap['return_received'] || 0,
      icon: <CheckDoubleLineIcon />,
    },
    {
      key: 'all',
      label: 'All',
      count: statusSummaryMap['all'] || 0,
      icon: <UnorderedListOutlined />,
    },
  ];
  return {
    statusSummary: data?.data || {},
    error,
    isLoading,
    TAB_ITEMS,
    reloadStatusSummary: mutate,
  };
};

export const acceptReturn = ({
  returnId,
  warehouseId,
  carrierId,
}: {
  returnId: string;
  warehouseId: string;
  carrierId: string;
}) => {
  const url = API.shipfast.acceptReturn(returnId);
  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({ warehouse_id: warehouseId, carrier_id: carrierId }),
  });
};

export const rejectReturn = ({
  returnId,
  rejectionReason,
}: {
  returnId: string;
  rejectionReason: string;
}) => {
  const url = API.shipfast.rejectReturn(returnId);
  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({ rejection_reason: rejectionReason }),
  });
};

export const useIsEditDisabledInShippingDrawer = () => {
  const { shipfastOrder } = useShipfastOrder();
  const orderStatus = shipfastOrder?.attributes?.status;
  const { isReturnsPage } = useIsReturnsPage();
  let isEditDisabled = false;
  const { query } = useQuery();
  const returnStatus = query.status as ReturnStatus;

  if (isReturnsPage) {
    isEditDisabled = returnStatus !== 'return_requested';
  } else {
    isEditDisabled = ![
      'pending',
      //   'ready_to_ship',
      'new',
      //   'ready_for_pickup',
    ].includes(orderStatus);
  }
  return {
    isEditDisabled,
  };
};

export const verifyDelivery = ({
  returnId,
  rejectionReason,
  status,
}: {
  returnId: string;
  rejectionReason?: string;
  status: 'verified' | 'damaged' | 'rejected';
}) => {
  const url = API.shipfast.verifyDelivery(returnId);
  const body = JSON.stringify({
    rejection_reason: rejectionReason,
    status,
  });
  return fetcher(url, {
    method: 'post',
    body,
  });
};

export const useReturnStatus = () => {
  const { query } = useQuery();
  const status = query.status as ReturnStatus;
  const isReturnRequested = status === 'return_requested';

  return {
    status,
    isReturnRequested,
  };
};

export const useIsSupportPage = () => {
  const { pathname } = useLocation();
  const isSupportPage = pathname.includes('support');
  return {
    isSupportPage,
  };
};

export const useSupportStatusSummary = () => {
  const url = API.shipfast.getStatusSummary;
  const { data, error, isLoading, mutate } = useSWRImmutable(url);

  const statusSummaryList = (data?.data || []) as ShipfastStatusSummary[];

  const statusSummaryMap = statusSummaryList?.reduce(
    (acc, item) => {
      acc[item.status] = item.count;
      return acc;
    },
    {} as Record<ShipfastOrderStatus, number>,
  );

  const TAB_ITEMS = [
    {
      key: 'open',
      label: 'Open',
      count: statusSummaryMap['open'] || 0,
      icon: <SupportLineIcon style={{ fill: 'transparent', fontSize: 20 }} />,
    },
    {
      key: 'closed',
      label: 'Closed',
      count: statusSummaryMap['closed'] || 0,
      icon: <CheckDoubleLineIcon style={{ fontSize: 20 }} />,
    },
  ];
  return {
    statusSummary: data?.data || {},
    error,
    isLoading,
    TAB_ITEMS,
    reloadStatusSummary: mutate,
  };
};

export const useShipfastSupportData = () => {
  //   const url = API.shipfast.getSupportCategory;
  //   const { data, error, isLoading, mutate } = useSWRImmutable(url);

  const supportTickets = [
    {
      external_id: 'AEM0NC3',
      created_at: dayjs(),
      awb_no: '5351022502',
      type: 'Pickup Issues',
      history: 'Ticket created by user',
      status: 'overdue',
      updated_at: '13 Nov 24',
    },
    // add more data
    {
      external_id: 'AEM0NC32',
      created_at: dayjs(),
      awb_no: '5351022502',
      type: 'Pickup Issues',
      history: 'Ticket created by user',
      status: 'overdue',
      updated_at: '13 Nov 24',
    },
    // add 5 more data with different id
    {
      id: 'AEM0NC35',
      created_at: dayjs(),
      awb_no: '5351022502',
      type: 'Pickup Issues',
      history: 'Ticket created by user',
      status: 'overdue',
      updated_at: '13 Nov 24',
    },
    // add 5 more data with different id
    {
      external_id: 'AEM0NC31',
      created_at: dayjs(),
      awb_no: '5351022502',
      type: 'Pickup Issues',
      history: 'Ticket created by user',
      status: 'overdue',
      updated_at: '13 Nov 24',
    },
  ];

  return {
    supportTickets,
    isLoading: false,
    error: null,
    reloadSupportTickets: () => {},
    size: 1,
    setSize: () => {},
    meta: {},
    hasMore: false,
  };
};

const formatPricing = (
  pricing: ShipfastPricing[],
  shipfastCarriers: ShipFastCarrier[],
) => {
  return (pricing = pricing.map((item, index) => {
    const carrier = shipfastCarriers.find(
      (carrier) => carrier.id === item.carrier,
    );

    const serviceLevelisRoad = carrier?.attributes?.service_level === 'road';
    const roadTotalFwd = serviceLevelisRoad
      ? (
          Number(item.frwd_charges.base || 0) +
          Number(item.frwd_charges.cod || 0)
        ).toFixed(2)
      : 0;
    const airTotalFwd = !serviceLevelisRoad
      ? (
          Number(item.frwd_charges.base || 0) +
          Number(item.frwd_charges.cod || 0)
        ).toFixed(2)
      : 0;

    const roadTotalRto = serviceLevelisRoad
      ? (
          Number(item.rto_charges.base || 0) + Number(item.rto_charges.cod || 0)
        ).toFixed(2)
      : 0;
    const airTotalRto = !serviceLevelisRoad
      ? (
          Number(item.rto_charges.base || 0) + Number(item.rto_charges.cod || 0)
        ).toFixed(2)
      : 0;
    return {
      ...item,
      id: index,
      frwd_charges: {
        ...item.frwd_charges,
        roadTotal: roadTotalFwd,
        airTotal: airTotalFwd,
      },
      rto_charges: {
        ...item.frwd_charges,
        roadTotal: roadTotalRto,
        airTotal: airTotalRto,
      },
      mode_of_transport: carrier?.attributes?.service_level,
    };
  }));
};

export const usePricing = (orderId?: string) => {
  const { shipfastCarriers } = useShipfastCarriers();
  const { query } = useQuery();
  const orderIdValue = orderId || (query.orderId as string);
  const url = orderIdValue ? API.shipfast.getOrderPricing(orderIdValue) : null;
  const { data, error, isLoading, mutate, isValidating } = useSWR(url, {
    dedupingInterval: 1000 * 60 * 5, // 10 minutes in milliseconds
  });
  let pricing = (data?.data || []) as ShipfastPricing[];
  const isProd = isProduction();
  pricing = formatPricing(pricing, shipfastCarriers);

  return {
    pricing: isProd
      ? pricing.filter((el) => {
          if (el.mode_of_transport === 'road') {
            return Number(el.frwd_charges.roadTotal) !== 0;
          }
          return Number(el.frwd_charges.airTotal) !== 0;
        })
      : pricing,
    error: error || data?.error?.msg,
    isLoading: isLoading || isValidating,
    reloadPricing: mutate,
  };
};

export const getShipfastOrderCharges = ({
  pricing,
  carrierId,
}: {
  pricing: ShipfastPricing[];
  carrierId: string;
}) => {
  const carrierCharges = pricing.find((item) => item.carrier === carrierId);
  return carrierCharges;
};

export const cloneOrder = ({ orderId }: { orderId: string }) => {
  const url = API.shipfast.cloneOrder(orderId);
  return fetcher(url);
};

export const useShipfastChannels = () => {
  const url = API.shipfast.shipfastChannels;
  const { data, error, isLoading } = useSWRImmutable(url);
  const channels = (data?.result || []) as ShipfastChannel[];
  return {
    channels,
    error,
    isLoading,
  };
};

export const useShipfastTags = () => {
  const url = API.shipfast.shipfastTags;
  const { data, error, isLoading } = useSWR(url);
  const tags = (data?.data || []) as string[];
  return {
    tags,
    error,
    isLoading,
  };
};

export const useShipfastDashboardDetails = () => {
  const url = API.shipfast.getDashboardDetails;
  const {
    data,
    error,
    isLoading,
    mutate: reloadDashboardDetails,
  } = useSWR(url);
  const shipfastDashboardDetails = (data || {}) as ShipfastDashboardDetails;

  const {
    count_of_orders_processed,
    percentage_difference_orders_processed,
    count_of_orders_delayed,
    percentage_difference_order_delayed,
    count_of_rtos,
    percentage_difference_rto_count,
    count_of_ndrs,
    percentage_difference_ndr_count,
    count_of_orders_to_be_processed,
    percentage_difference_orders_to_be_processed,
    count_order_with_weight_discrepancy,
    percentage_difference_count_order_with_weight_discrepancy,
    actionable_items_count,
    percentage_difference_actionable_items_count,
    disconnected_channels,
    percentage_difference_disconnected_channels,
    ready_for_pickup_today,
    ready_for_pickup_tomorrow,
  } = shipfastDashboardDetails;

  const summaryItems = [
    {
      title: 'Orders processed this week',
      value: count_of_orders_processed,
      percentage: percentage_difference_orders_processed,
    },
    {
      title: 'Orders delayed for delivery',
      value: count_of_orders_delayed,
      percentage: percentage_difference_order_delayed,
    },
    {
      title: 'RTO Count (this week)',
      value: count_of_rtos,
      percentage: percentage_difference_rto_count,
    },
    {
      title: 'NDR Count (this week)',
      value: count_of_ndrs,
      percentage: percentage_difference_ndr_count,
    },
  ];

  const attentionItems = [
    {
      title: 'Orders to be processed',
      value: count_of_orders_to_be_processed,
      percentage: percentage_difference_orders_to_be_processed,
    },
    {
      title: 'Number of actionable items',
      value: actionable_items_count,
      percentage: percentage_difference_actionable_items_count,
    },
    // {
    //   title: 'Orders with weight discrepancies',
    //   value: count_order_with_weight_discrepancy,
    //   percentage: percentage_difference_count_order_with_weight_discrepancy,
    // },
    {
      title: 'Disconnected channels',
      value: disconnected_channels,
      percentage: percentage_difference_disconnected_channels,
    },
  ];

  return {
    summaryItems,
    attentionItems,
    shipfastDashboardDetails,
    error,
    isLoading,
    reloadDashboardDetails,
  };
};

export const surfaceTypeMap: Record<SurfaceType, ShipfastCarrierMode> = {
  air: 'airTotal',
  surface: 'roadTotal',
} as const;

export const useShipfastFilteredCarriers = ({
  surfaceType,
  sortOption,
  orderId,
}: {
  surfaceType: SurfaceType;
  sortOption: SortOption;
  orderId: string;
}) => {
  const { finalCourierList } = useShipfastCarrierOrderWise(orderId);

  const showAll = surfaceType === 'all';
  const filteredShipfastCarriersBySurfaceType = showAll
    ? finalCourierList
    : finalCourierList?.filter((item) => item.service_level === surfaceType);

  const filteredShipfastCarriers =
    sortOption === 'Best'
      ? [...filteredShipfastCarriersBySurfaceType]
      : [...filteredShipfastCarriersBySurfaceType].sort((a, b) => {
          if (sortOption === 'Cheapest') {
            const aCharges = a.pricing.frwd_charges;
            const bCharges = b.pricing.frwd_charges;
            const aChargesMode = surfaceTypeMap[a.service_level];
            const bChargesMode = surfaceTypeMap[b.service_level];
            return (
              Number(aCharges[aChargesMode] || 0) -
              Number(bCharges[bChargesMode] || 0)
            );
          }
          if (sortOption === 'Fastest') {
            return dayjs(a.pricing.edd.delivery.datetime).diff(
              dayjs(b.pricing.edd.delivery.datetime),
            );
          }
        });
  return {
    filteredShipfastCarriers,
  };
};

export const useShipfastReturn = () => {
  const { query } = useQuery();
  const { returnId, mode = 'view', editType } = query;
  const url = returnId ? API.shipfast.getReturn(returnId) : null;
  const { reloadReturns } = useShipfastReturns();
  const { data, error, isLoading, mutate } = useSWR(url);
  const shipfastReturn = (data?.data || {}) as Return;
  const items = shipfastReturn?.attributes?.return_items;
  const totalItemCost = getTotalItemsCost(items);
  return {
    shipfastReturn,
    error,
    isLoading,
    reloadShipfastReturn: () => {
      mutate();
      reloadReturns();
    },
    mode: mode as ShipfastOrderMode,
    editType: editType as ShipfastOrderEditType,
    totalItemCost,
  };
};

export const useIsPickupPage = () => {
  const { pathname } = useLocation();
  return {
    isPickupPage: pathname.startsWith('/pickups'),
  };
};

export const useShowSearchBar = () => {
  const { isReturnsPage } = useIsReturnsPage();
  const { isOrderPage } = useIsOrderPage();
  const { isDiscrepanciesPage } = useIsDiscrepanciesPage();
  return {
    showSearchBar: isReturnsPage || isOrderPage || isDiscrepanciesPage,
  };
};

export const patchReturnWarehousePickupId = async ({
  returnId,
  warehouse_id,
}: {
  returnId: string;
  warehouse_id: string;
}) => {
  const url = API.shipfast.patchReturnWarehousePickupId(returnId);
  return await fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      return: { warehouse_id },
    }),
  });
};

export const useShipfastProfile = () => {
  const url = API.shipfast.getProfile;
  const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(url);
  const shipfastProfile = (data?.data || {}) as ShipfastProfile;
  const isDemo = shipfastProfile?.attributes?.is_demo;
  const walletBalance = Number(shipfastProfile?.attributes?.wallet_balance);
  const walletHoldBalance =
    Number(shipfastProfile?.attributes?.wallet_hold_balance) * -1;
  const { from, to } = useShipfastDateFilters();
  const isDateFilterApplied = from && to;
  const passbookSummary = [
    {
      label: 'Available balance',
      value: walletBalance.toFixed(2),
      type: 'currency',
    },
    {
      label: 'Hold balance',
      value: walletHoldBalance.toFixed(2),
      type: 'currency',
    },
    {
      label: 'Total balance',
      value: (walletBalance + walletHoldBalance).toFixed(2),
      type: 'currency',
    },
  ];

  return {
    shipfastProfile,
    error: error || data?.error?.message,
    isLoading,
    reloadShipfastProfile: mutate,
    isValidating,
    isDemo,
    passbookSummary: isDateFilterApplied ? [] : passbookSummary,
    walletBalance,
    isWalletConnected: walletBalance > 0,
    isGstConnected: !!shipfastProfile?.attributes?.gst_no,
    isOnboardingSkipped: shipfastProfile?.attributes?.onboarding_done,
    isAiCallingEnabled: shipfastProfile?.attributes?.calls_enabled || false,
    isAccountSettingsEnabled:
      shipfastProfile?.attributes?.flipper_features?.includes(
        'account_settings',
      ),
    isWeightDiscrepancyEnabled:
      shipfastProfile?.attributes?.flipper_features?.includes(
        'weight_discrepancies',
      ),
  };
};

export const useShipfastFlipperFeature = (feature: string) => {
  const { shipfastProfile } = useShipfastProfile();
  return {
    isFeatureEnabled:
      shipfastProfile?.attributes?.flipper_features?.includes(feature),
  };
};

const formatReturnPricing = (pricing: ReturnCharges[]) => {
  return (pricing = pricing.map((item, index) => {
    return {
      ...item,
      id: index,
    };
  }));
};

export const useReturnsPricing = (returnId?: string) => {
  const { query } = useQuery();
  const returnIdValue = returnId || (query.returnId as string);
  const url = returnIdValue
    ? API.shipfast.getReturnsPricing(returnIdValue)
    : null;
  const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(url);
  let pricing = (data?.data || []) as ReturnCharges[];
  pricing = formatReturnPricing(pricing);

  return {
    pricing,
    error: error || data?.error?.msg,
    isLoading: isLoading || isValidating,
    reloadPricing: mutate,
  };
};

type ReturnCarrierWithPricing = {
  id: string;
  carrier: ShipFastCarrier;
  pricing: ReturnCharges;
  service_level: ShipFastCarrier['attributes']['service_level'];
};

export const useShipfastCarrierReturnWise = (returnId?: string) => {
  const { query } = useQuery();
  const returnIdVal = returnId || query.returnId;

  const { pricing, reloadPricing, isLoading, error } =
    useReturnsPricing(returnIdVal);
  const { shipfastCarriers } = useShipfastCarriers();

  const finalCourierList: ReturnCarrierWithPricing[] = [];

  const surfaceCarriers: ReturnCarrierWithPricing[] = [];
  const airCarriers: ReturnCarrierWithPricing[] = [];

  pricing.forEach((item) => {
    const carrier = shipfastCarriers.find(
      (carrier) => carrier.id === item.carrier,
    );
    const roadChargesExist = carrier?.attributes?.service_level == 'road';

    if (roadChargesExist) {
      const data = {
        id: item.carrier + item.id,
        carrier: carrier!,
        pricing: item,
        service_level: 'surface',
      };
      surfaceCarriers.push(data);
      finalCourierList.push(data);
    }

    const airChargesExist = ['air', 'sdd', 'ndd'].includes(
      carrier?.attributes?.service_level,
    );

    if (airChargesExist) {
      const data = {
        id: item.carrier + item.id,
        carrier: carrier!,
        pricing: item,
        service_level: 'air',
      };
      surfaceCarriers.push(data);
      finalCourierList.push(data);
    }
  });

  return {
    finalCourierList: finalCourierList,
    surfaceCarriers,
    airCarriers,
    shipfastCarriersCountMap: {
      surface: surfaceCarriers.length,
      air: airCarriers.length,
      all: finalCourierList.length,
    },
    reloadPricing,
    isLoading,
    error,
  };
};

export const useShipfastReturnsFilteredCarriers = ({
  surfaceType,
  sortOption,
  returnId,
}: {
  surfaceType: SurfaceType;
  sortOption: SortOption;
  returnId: string;
}) => {
  const { finalCourierList } = useShipfastCarrierReturnWise(returnId);
  const showAll = surfaceType === 'all';

  const filteredShipfastCarriersBySurfaceType = showAll
    ? finalCourierList
    : finalCourierList?.filter((item) => item.service_level === surfaceType);

  const filteredShipfastReturnCarriers =
    sortOption === 'Custom'
      ? [...filteredShipfastCarriersBySurfaceType]
      : [...filteredShipfastCarriersBySurfaceType].sort((a, b) => {
          if (sortOption === 'Cheapest') {
            const aCharges = a.pricing.return_charges.road;
            const bCharges = b.pricing.return_charges.road;
            return Number(aCharges) - Number(bCharges);
          }
          if (sortOption === 'Fastest') {
            return dayjs(a.carrier.attributes.delivery_date).diff(
              dayjs(b.carrier.attributes.delivery_date),
            );
          }
        });
  return {
    filteredShipfastReturnCarriers,
  };
};

export const rechargeWallet = ({ amount }: { amount: string }) => {
  const url = API.shipfast.walletRecharge;
  return fetcher<{ data: RechargeWalletResponse }>(url, {
    method: 'post',
    body: JSON.stringify({ amount: Number(amount) }),
  });
};

export const getRechargeStatus = (wallet_recharge_id: string) => {
  const url = API.shipfast.walletRechargeStatus(wallet_recharge_id);
  return fetcher<{ data: RechargeWalletResponse }>(url, {
    method: 'post',
  });
};

export const bulkCreateOrders = ({ ordersFile }: { ordersFile: File }) => {
  const url = API.shipfast.bulkCreateOrders;
  const formData = new FormData();
  formData.append('orders_file', ordersFile);
  return fetcher(url, {
    method: 'post',
    body: formData,
  });
};

type PickupItem = {
  title: string;
  value: number;
  icon: React.ReactNode;
  type: string;
  today: boolean;
};

export const useShipfastPickupDetails = () => {
  const { shipfastDashboardDetails } = useShipfastDashboardDetails();
  const { ready_for_pickup_today, ready_for_pickup_tomorrow } =
    shipfastDashboardDetails;
  const readyFoPickupTodayList: PickupItem[] = [];
  const readyFoPickupTomorrowList: PickupItem[] = [];
  const { shipfastCarriers } = useShipfastCarriers();
  Object.entries(ready_for_pickup_today || {}).forEach(([carrierId, count]) => {
    const carrier = shipfastCarriers?.find(
      (carrier) => carrier.id === carrierId,
    );
    readyFoPickupTodayList.push({
      title: carrier?.attributes?.name!,
      value: count || 0,
      icon: CourierIconMap[carrier?.attributes?.code],
      type: 'pickupItem',
      today: true,
    });
  });

  Object.entries(ready_for_pickup_tomorrow || {}).forEach(
    ([carrierId, count]) => {
      const carrier = shipfastCarriers?.find(
        (carrier) => carrier.id === carrierId,
      );
      readyFoPickupTomorrowList.push({
        title: carrier?.attributes?.name!,
        value: count || 0,
        icon: CourierIconMap[carrier?.attributes?.code],
        type: 'pickupItem',
        today: false,
      });
    },
  );

  return {
    readyFoPickupTodayList,
    readyFoPickupTomorrowList,
    showPickupItems:
      readyFoPickupTodayList.length > 0 || readyFoPickupTomorrowList.length > 0,
  };
};

export const reAttemptShipmentDelivery = ({
  shipmentId,
}: {
  shipmentId: string;
}) => {
  const url = API.shipfast.reAttemptShipmentDelivery(shipmentId);
  const body = JSON.stringify({
    instruction: 'RAD',
    comments: 'Deliver ASAP',
    scheduled_delivery_date: '25-DEC-2025',
    scheduled_delivery_slot: '1',
  });
  return fetcher(url, {
    method: 'post',
    body,
  });
};

export const addWarehouse = (values: {
  name: string;
  phone_number: string;
  street_address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}) => {
  const url = API.shipfast.getWarehousePickupList;
  const { name, phone_number, ...restProps } = values;
  const payload = {
    name,
    phone_number: phone_number.replace('+91', ''),
    address_attributes: restProps,
  };

  return fetcher(url, {
    method: 'post',
    body: JSON.stringify(payload),
  });
};

export const useIsOnboardingDone = () => {
  const {
    integrations,
    isLoading: isIntegrationsLoading,
    isShopifyConnected,
  } = useIntegrations();
  const isShopifySourceApplicationShipfast =
    integrations.find((el) => el.integration_name === 'SHOPIFY')
      ?.source_application_type === 'shipfast';
  const isShopifyConnectedWithShipfast =
    isShopifyConnected && isShopifySourceApplicationShipfast;
  const { packages, isLoading: isPackagesLoading } = useShipfastPackages();
  const isPackagesAdded = packages.length > 0;
  const { isWarehouseAdded, isLoading } = useShipfastWarehousePickupList();
  const { isWalletConnected, isOnboardingSkipped, error } =
    useShipfastProfile();
  /**
   * TODO: modify variable name to isProfileConnected
   */
  const isGstConnected = error == null;
  const isCompulsoryStepsDone =
    isWarehouseAdded && isGstConnected && isPackagesAdded;
  const isAllStepsDone =
    isCompulsoryStepsDone && isShopifyConnectedWithShipfast;

  return {
    isLoading: isIntegrationsLoading || isLoading || isPackagesLoading,
    isOnboardingDone:
      isAllStepsDone || (isCompulsoryStepsDone && isOnboardingSkipped),
    isShopifyConnectedWithShipfast,
    isWarehouseAdded,
    isWalletConnected,
    isGstConnected,
    isCompulsoryStepsDone,
    isAllStepsDone,
    isPackagesAdded,
  };
};

export const createShipfastProfile = ({
  state_id,
  logo,
  gst_no,
  brand_name,
  name,
  coupon_code,
}: {
  state_id: string;
  logo: File;
  gst_no: string;
  brand_name: string;
  name: string;
  coupon_code: string;
}) => {
  const url = API.shipfast.getProfile;
  const formData = new FormData();
  formData.append('state_id', state_id);
  formData.append('logo', logo);
  formData.append('gst_no', gst_no);
  formData.append('brand_name', brand_name);
  formData.append('name', name);
  if (coupon_code) {
    formData.append('coupon_code', coupon_code);
  }
  return fetcher(url, {
    method: 'upload',
    body: formData,
  });
};

export const useStateList = () => {
  const url = API.shipfast.stateList;
  const { data, error, isLoading } = useSWR(url);
  const stateList = (data?.data || []) as StateType[];

  return {
    stateList,
    error,
    isLoading,
  };
};
export const createManualReturn = ({
  returnItems,
}: {
  returnItems: ManualReturnItem;
}) => {
  const url = API.shipfast.getReturns;
  const updatedReturnItemsAttributes =
    returnItems.return_items_attributes.filter((item) => item.quantity > 0);

  return fetcher(url, {
    method: 'post',
    body: JSON.stringify({
      ...returnItems,
      return_items_attributes: updatedReturnItemsAttributes,
    }),
  });
};

export const useShipfastPackages = () => {
  const url = API.shipfast.getPackages;
  const { data, error, isLoading, mutate } = useSWR(url);
  const packages = (data?.data || []) as Package[];
  const defaultPackage =
    packages.find((item) => item.attributes.default) || packages?.[0];
  const defaultPackageId = defaultPackage?.id;
  return {
    packages,
    error,
    isLoading,
    reloadPackages: mutate,
    defaultPackageId,
    defaultPackage,
  };
};

export const patchShipfastReturn = ({
  returnId,
  values,
}: {
  returnId: string;
  values: any;
}) => {
  const url = API.shipfast.patchReturn(returnId);
  return fetcher(url, {
    method: 'PATCH',
    body: JSON.stringify({
      return: values,
    }),
  });
};

export const addPackageDetails = (values: {
  length: string;
  width: string;
  height: string;
  name: string;
  package_type: string;
  dead_weight: string;
}) => {
  const url = API.shipfast.getPackages;
  const { length, width, height, name, package_type, dead_weight } = values;
  const payload = {
    length,
    width,
    height,
    name,
    default: true,
    package_type,
    dead_weight,
  };

  return fetcher(url, {
    method: 'post',
    body: JSON.stringify(payload),
  });
};

export const updateShipfastProfile = () => {
  const url = API.shipfast.getProfile;
  const body = JSON.stringify({
    onboarding_done: true,
  });
  return fetcher(url, {
    method: 'PATCH',
    body,
  });
};
export const getShipmentsEventDownloadInfo = ({
  start,
  end,
  ...restParams
}: {
  start: number;
  end: number;
  [key: string]: any;
}) => {
  const { start_time, end_time, ...restQueryParams } = restParams;
  const queryParams = makeQueryParams(restQueryParams);

  const url =
    API.shipfast.getShipmentsEventDownload({ start, end }) + '&' + queryParams;
  return fetcher<DownloadInfo>(url);
};

export const useShippingRuleCarriers = () => {
  const url = API.shipfast.getRuleCarriers;
  const { data, error, isLoading, mutate, isValidating } = useSWRImmutable(url);
  const shippingRuleCarriers = (data?.data || []) as ShippingRuleCarrier[];

  return {
    shippingRuleCarriers,
    error,
    isLoading,
    isValidating,
    reloadShippingCarriers: mutate,
  };
};

export const useShippingRuleCarriersSummary = () => {
  const url = API.shipfast.getRuleCarrierSummary;
  const { data, error, isLoading, mutate } = useSWR(url);
  return {
    summary: data as ShippingRuleCarrierSummary,
    error,
    isLoading,
    reloadSummary: mutate,
  };
};

export const updateShippingRuleCarrierStatus = async ({
  carrierId,
  updatedStatus,
}: {
  carrierId: string;
  updatedStatus: boolean;
}) => {
  const url = `${API.shipfast.getRuleCarriers}/${carrierId}`;
  return await fetcher(url, {
    method: 'put',
    body: JSON.stringify({
      status: updatedStatus,
    }),
  });
};

export const useShippingRules = () => {
  const url = API.shipfast.getShippingRules;
  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable(url);
  return {
    shippingRules: (data?.data as ShippingRule[]) || [],
    error,
    isLoading,
    isValidating,
    reloadShippingRules: mutate,
  };
};

export const createShippingRule = async ({ ruleData }: { ruleData: any }) => {
  const url = API.shipfast.createShippingRule;
  return await fetcher(url, {
    method: 'post',
    body: JSON.stringify(ruleData),
  });
};

export const updateShippingRule = async ({
  ruleId,
  updateData,
}: {
  ruleId: string;
  updateData: any;
}) => {
  const url = `${API.shipfast.getShippingRules}/${ruleId}`;
  return await fetcher(url, {
    method: 'put',
    body: JSON.stringify(updateData),
  });
};

export const bulkUpdateShippingRules = async ({
  updatedRules,
}: {
  updatedRules: {
    id: string;
    priority?: number;
    _destroy?: boolean;
  }[];
}) => {
  const url = API.shipfast.bulkUpdateShippingRules;
  return await fetcher(url, {
    method: 'put',
    body: JSON.stringify({ shipping_rules: updatedRules }),
  });
};

export const reattemptNdrDelivery = async ({
  shipmentId,
  body,
}: {
  shipmentId: string;
  body: any;
}) => {
  const sendBody = Object.keys(body).length > 0;
  const url = API.shipfast.reAttemptShipmentDelivery(shipmentId);
  return fetcher(url, {
    method: 'post',
    body: sendBody ? JSON.stringify(body) : undefined,
  });
};
