import { Row, Space, Typography } from 'antd';
import { ButtonLine } from '@/ui-kit';
import { ClockCircleOutlined, PhoneOutlined } from '@ant-design/icons';
import { PencilLineIcon } from 'velocity_engineering_ve_icons';

import { useShipfastWarehouseDetails } from '@/swr/shipfast';

import styles from './pickup_details.module.less';

type Props = {
  warehouseId: string;
  handleEdit?: () => void;
};

const PickupDetails = ({ warehouseId, handleEdit }: Props) => {
  const { warehouseDetails } = useShipfastWarehouseDetails(warehouseId);

  return (
    <>
      <Row
        align="top"
        justify="space-between"
        wrap={false}
        className={styles.popoverContent}
      >
        <Space.Compact direction="vertical">
          <Typography.Text className={styles.headerText}>
            {warehouseDetails?.attributes?.name}
          </Typography.Text>
          <Typography.Paragraph
            type="secondary"
            ellipsis={{ rows: 2 }}
            className={styles.bodyText}
          >
            {warehouseDetails?.attributes?.pickup_address?.street_address}
          </Typography.Paragraph>
          <Typography.Text type="secondary" className={styles.bodyText}>
            ID: {warehouseDetails?.attributes?.unique_id}
          </Typography.Text>
        </Space.Compact>
        {handleEdit && (
          <ButtonLine
            size="small"
            className={styles.editButton}
            onClick={handleEdit}
          >
            <PencilLineIcon />
          </ButtonLine>
        )}
      </Row>
      <Space.Compact direction="vertical" className={styles.additionalInfo}>
        {/* <Space>
          <ClockCircleOutlined />
          <Typography.Text>8am to 6pm</Typography.Text>
        </Space> */}

        <Space>
          <PhoneOutlined />

          <Typography.Text>
            {warehouseDetails?.attributes?.phone_number}
          </Typography.Text>
        </Space>
      </Space.Compact>
    </>
  );
};

export default PickupDetails;
