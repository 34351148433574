import useSWR, { mutate } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { API } from '@/utils/api_config';
import { BankAccountWithSubscription, Company, LoanAccount } from './types';
import { fetcher } from '@/ve-utils/api_call';
import { useApplication } from '../apply';
import { Mutate, mutateSWR } from '@/swr/utils';
import { filterBankDetailsOnNbfc } from '../../common/repayment_methods/nach_mandate/nach_utils';
import { makeQueryParams } from '@/ve-utils/common';

const COMPANY_LOADING_TEXT = 'Fetching Company Details...';

export const useCompany = () => {
  const { data, error, isLoading } = useSWRImmutable(API.finance.getCompany);

  return {
    company: data?.data as Company,
    isLoading,
    error,
    companyLoadingText: COMPANY_LOADING_TEXT,
  };
};

export const useCreditLine = () => {
  const { data, error, isLoading } = useSWRImmutable(API.finance.loanAccounts);

  const result = data?.data as LoanAccount[];

  // result?.creditLine comes from changing creditline, refer to changeCreditLine function.
  const creditLine = data?.creditLine as LoanAccount;
  const updatedResult = result
    ?.filter((acc) => acc.loan_type !== 'credit_line')
    ?.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )
    ?.map((acc, index) => ({ ...acc, index: index + 1 }));

  const activeCredit = result?.find(
    (a) => a.status === 'active' && a.loan_type !== 'credit_line',
  );

  return {
    creditLine: creditLine || activeCredit || updatedResult?.[0],
    loanAccounts: updatedResult,
    activeLoanAccountUUID: updatedResult?.find((a) => a.status === 'active')
      ?.uuid,
    isLoading,
    error,
  };
};

export const changeCreditLine = async ({
  loanAccountUUID,
  mutate,
}: {
  loanAccountUUID: string;
  mutate: Mutate;
}) => {
  await mutate(
    API.finance.loanAccounts,
    async (result) => {
      const loanAccounts = result?.data as LoanAccount[];
      const creditLine = loanAccounts?.find(
        (el) => el.uuid === loanAccountUUID,
      );
      return { ...result, creditLine };
    },
    { revalidate: false },
  );
};

export const setCompanyGST = async ({ pan }: { pan: string }) => {
  return await fetcher(API.finance.getCompanyGst(pan), {
    method: 'post',
  });
};

export const useCompanyGST = (pan: string) => {
  const { data, error, isLoading } = useSWRImmutable(
    pan ? API.finance.getCompanyGst(pan) : undefined,
  );

  return {
    companyGst: data?.data,
    isLoading,
    error,
  };
};

export const useSubscriptions = () => {
  const { application } = useApplication();
  const {
    type_details: { nbfc_id },
  } = application;
  const { data, error, isLoading } = useSWRImmutable(
    API.finance.getSubscriptions(nbfc_id.toString()),
  );

  return {
    subscriptions: data?.data,
    isLoading,
    error,
  };
};

export const useBankAccounts = () => {
  const { data, error, isLoading } = useSWRImmutable(
    API.finance.getBankAccounts,
  );
  const { creditLine } = useCreditLine();
  const { application } = useApplication();
  const bankAccounts = data?.data as BankAccountWithSubscription[];
  const nbfc_id = application?.type_details?.nbfc_id || creditLine?.lender?.id;

  const bankAccountsWithoutSubscriptions = filterBankDetailsOnNbfc(
    bankAccounts || [],
    nbfc_id.toString(),
  );

  return {
    bankAccounts,
    bankAccountsWithoutSubscriptions,
    isLoading,
    error,
  };
};

export const authorizeSubscription = async ({
  subscription_id,
  formData,
}: {
  subscription_id: string;
  formData: FormData;
}) => {
  const { ok } = await fetcher(
    API.finance.authorizeSubscription(subscription_id),
    {
      method: 'upload',
      body: formData,
    },
  );
  return { ok };
};

export const createInsightsApplication = async () => {
  const url = API.finance.createInsightsApplication;
  const body = JSON.stringify({
    insight_application: {
      utm_params: {
        utm_source: 'rbf',
      },
    },
  });
  const { ok } = await fetcher(url, { method: 'post', body });
  return { ok };
};

export const activateInsightsApplication = async () => {
  const url = API.finance.updateInsightsApplicationState + '?event=activate';
  const { ok } = await fetcher(url, { method: 'put' });
  if (ok) {
    mutateSWR(API.root.getProfile, mutate);
  }
  return { ok };
};

export const addInsightsUser = async () => {
  const url = API.finance.addInsightsUser;
  const { ok } = await fetcher(url, { method: 'put' });
  return { ok };
};

export const updateFinanceApplicationInsightsEligibility = async (
  insightsEligibility: boolean,
) => {
  const url = API.finance.updateFinanceApplicationInsightsEligibility;
  const body = JSON.stringify({
    status: insightsEligibility ? 'eligible' : 'not eligible',
  });
  const { ok } = await fetcher(url, { method: 'put', body });
  return { ok };
  //mutate profile on ok
};

export const useResanctionApplications = () => {
  const { data, error, isLoading } = useSWR(
    API.finance.getResanctionApplications,
  );
  return {
    applications: data?.data,
    isLoading,
    error,
  };
};

export const useTopupEligibility = () => {
  const { data, error, isLoading } = useSWR(API.finance.getTopupEligibility);
  return {
    topupEligibility: data?.data,
    isLoading,
    error,
  };
};

export const useTopupApplications = () => {
  const { data, error, isLoading } = useSWR(API.finance.getTopupApplications);
  return {
    topupApplications: data?.data,
    isLoading,
    error,
  };
};

export const useCreateTopupApplication = async ({ uuid }: { uuid: string }) => {
  const url = API.finance.createTopupApplication;
  const body = JSON.stringify({
    product_event_thread_uuid: uuid,
  });

  const { ok, data } = await fetcher(url, {
    method: 'post',
    body,
  });

  if (ok) {
    mutateSWR(API.finance.getTopupApplications, mutate);
  }

  return { ok, data: data?.data };
};

export const useRequestedDocumentTypes = () => {
  const { application } = useApplication();
  const application_uuid = application?.uuid;
  const url = application_uuid
    ? API.finance.requestedDocumentTypes(application_uuid)
    : undefined;
  const { data, error, isLoading } = useSWR(url);
  const result = data?.data
    ?.filter(
      (doc) =>
        doc.requested_by !== 'system' &&
        doc.document_key !== 'related_party_documents',
    )
    ?.map((type) => type.document_key);

  return {
    requestedDocumentTypes: result,
    isLoading,
    error,
  };
};

export const verifyPan = async ({
  pan,
  payload,
}: {
  pan: string;
  payload?: never;
}) => {
  const url = API.finance.getCompany;
  const params = makeQueryParams(
    {
      pan,
    },
    [],
  );
  const body = payload ? JSON.stringify(payload) : undefined;
  const { ok, data } = await fetcher(url + `?${params}`, {
    method: 'put',
    body,
  });
  return { ok, data: data?.data };
};

export const getCompanyEntityDetails = async ({ pan }: { pan: string }) => {
  const url = API.finance.getCompanyEntityDetails;
  const params = makeQueryParams(
    {
      corporate_pan_number: pan,
    },
    [],
  );
  const { ok, data } = await fetcher(url + `?${params}`);

  return { ok, data: data?.data };
};

export const useMagicLink = ({
  application_uuid,
}: {
  application_uuid: string;
}) => {
  const url = API.finance.getMagicLink(application_uuid);
  const { data, error, isLoading } = useSWRImmutable(url);

  return {
    magicLink: data?.data?.magic_link,
    isLoading,
    error,
  };
};
