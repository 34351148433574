import DashboardLayout from '@/pages/shipfast/pages/dashboard/layout';
import { Navigate, type RouteObject } from 'react-router-dom';
import { error500Element } from '../utils';

const shipfastRoutes: RouteObject = {
  path: 'shipping',
  lazy: () => import('@/pages/shipfast/layout'),
  errorElement: error500Element,
  children: [
    {
      index: true,
      element: <Navigate to="dashboard" />,
    },
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        {
          index: true,
          lazy: () => import('@/pages/shipfast/pages/dashboard'),
        },
        {
          path: 'onboarding',
          lazy: () => import('@/pages/shipfast/pages/dashboard/onboarding'),
        },
      ],
    },
    {
      path: 'orders',
      lazy: () => import('@/pages/shipfast/pages/orders'),
    },
    {
      path: 'pickups',
      children: [
        {
          index: true,
          lazy: () => import('@/pages/shipfast/pages/manifests'),
        },
        {
          path: ':id',
          lazy: () => import('@/pages/shipfast/pages/manifest_details'),
        },
      ],
    },
    {
      path: 'billing',
      lazy: () => import('@/pages/shipfast/pages/billing'),
    },
    {
      path: 'returns',
      lazy: () => import('@/pages/shipfast/pages/returns'),
    },
    {
      path: 'discrepancies',
      lazy: () => import('@/pages/shipfast/pages/discrepancies'),
    },
    {
      path: 'analytics',
      lazy: () => import('@/pages/shipfast/pages/analytics'),
    },
    {
      path: 'rules',
      children: [
        {
          index: true,
          lazy: () => import('@/pages/shipfast/pages/rules'),
        },
        {
          path: 'add-rule',
          lazy: () => import('@/pages/shipfast/pages/rules/add-rule/wrapper'),
        },
        {
          path: 'courier',
          children: [
            {
              path: ':id',
              lazy: () =>
                import('@/pages/shipfast/pages/rules/courier_details'),
            },
          ],
        },
      ],
    },
    {
      path: 'settings',
      children: [
        {
          index: true,
          lazy: () => import('@/pages/shipfast/pages/settings'),
        },
        {
          path: 'profile',
          lazy: () => import('@/pages/shipfast/pages/settings/profile'),
        },
        {
          path: 'kyc',
          lazy: () => import('@/pages/shipfast/pages/settings/kyc'),
        },
        {
          path: 'address',
          lazy: () => import('@/pages/shipfast/pages/settings/address'),
        },
        {
          path: 'invoice',
          lazy: () => import('@/pages/shipfast/pages/settings/invoice'),
        },
        {
          path: 'communication',
          lazy: () => import('@/pages/shipfast/pages/settings/communication'),
        },
        {
          path: 'catalogue',
          lazy: () => import('@/pages/shipfast/pages/settings/catalogue'),
        },
      ],
    },
    {
      path: 'support',
      lazy: () => import('@/pages/shipfast/pages/support'),
    },
    {
      path: 'recharge',
      lazy: () => import('@/pages/shipfast/pages/recharge'),
    },
  ],
};

export default shipfastRoutes;
