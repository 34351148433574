import { errorNotification } from '@/ui-kit';
import { apiCall } from '@/ve-utils/api';

import { API } from '@/utils/api_config';
import { BankAccountWithSubscription } from '@/pages/finance/swr/user/types';

export const initializeNach = async (
  jwt,
  FallBackToNachFailed,
  params,
  onClose,
  popupActions,
  application_uuid,
) => {
  const config = {
    url:
      API.finance.initializeNachSubscription +
      `?application_uuid=${application_uuid}`,
    params,
  };

  try {
    const response = await apiCall('post', config);
    const link = response?.data?.data?.provider?.link;
    if (link) {
      popupActions.openPopup(link);
    } else {
      errorFallback();
    }
  } catch (err) {
    errorFallback(err?.response?.data?.error);
    onClose(true);
  }
};

const errorFallback = (error) => {
  errorNotification({
    message: error?.title || 'Error',
    description: error?.detail || 'Error Initializing Authorization',
  });
};

export const CreateBankAccount = async (data) => {
  const config = {
    url: API.finance.createBankAccounts,
    params: data,
  };

  try {
    const response = await apiCall('post', config);
    return response;
  } catch (e) {
    const errorMessage = e?.response?.data?.errors?.account_number;
    if (errorMessage) {
      errorNotification({
        message: 'Error',
        description: errorMessage,
      });
    } else {
      errorNotification({
        message: 'Error',
        description: 'Error Creating Bank details.',
      });
    }
  }
};

export const createSubScription = async () => {
  return '12893y19823619826';
};

// This is primarily used because the backend isnt filtering out the bank details which already has a subscription for the current nbfc of the user
// this function filters bank details for which the user already created a subscription
export const filterBankDetailsOnNbfc = (
  bankDetailsData: BankAccountWithSubscription[],
  nbfc_id: string,
) => {
  const filteredBankAccounts: BankAccountWithSubscription[] = [];
  bankDetailsData.forEach((bank_account) => {
    if (bank_account.subscription_details) {
      // if there is no subscription details for the bank account then we should show it to the user
      if (bank_account.subscription_details.length === 0)
        filteredBankAccounts.push(bank_account);
      // and if there are subscription but none are for the current nbfc we should should that to
      if (bank_account.subscription_details.length > 0) {
        let shouldShowThisBankAccount = true;
        bank_account.subscription_details.map((subscription) => {
          if (
            subscription.nbfc_id.toString() === nbfc_id.toString() &&
            ['active', 'pending'].includes(subscription.status)
          ) {
            shouldShowThisBankAccount = false;
          }
          return;
        });
        if (shouldShowThisBankAccount) filteredBankAccounts.push(bank_account);
      }
    }
  });
  return filteredBankAccounts;
};

export const getPrimaryAndSecondarySubscriptionAcc = ({
  bankAccounts,
  nbfc_id,
}) => {
  const primarySubscriptionAcc = [];
  const secondarySubscriptionAcc = [];
  for (const acc of bankAccounts) {
    const activeSubList = acc?.subscription_details?.filter((sub) => {
      if (nbfc_id === 2) {
        return [2, 12].indexOf(sub.nbfc_id) > -1 && sub.status === 'active';
      }

      return sub.nbfc_id === nbfc_id && sub.status === 'active';
    });
    if (activeSubList?.length > 0) {
      const primarySub = activeSubList.find((sub) => sub.is_primary);
      if (primarySub) {
        primarySubscriptionAcc.push({ ...acc, subscription: primarySub });
      } else {
        secondarySubscriptionAcc.push({
          ...acc,
          subscription: activeSubList[0],
        });
      }
    } else {
      const pendingSubList = acc?.subscription_details?.filter(
        (sub) => sub.nbfc_id === nbfc_id && sub.status === 'pending',
      );
      pendingSubList?.forEach((pendingSub) => {
        secondarySubscriptionAcc.push({ ...acc, subscription: pendingSub });
      });
    }
  }
  return {
    primary: primarySubscriptionAcc,
    secondary: secondarySubscriptionAcc,
  };
};

export const getCurrentBankSubscriptionFailureCount = (
  bankDetails,
  nbfc_id,
) => {
  let failure_count = 0;
  const current_sub = bankDetails?.subscription_details?.find(
    (subscription) => subscription.nbfc_id === nbfc_id,
  );
  if (current_sub) failure_count = current_sub?.failure_count || 0;
  return failure_count;
};

export const getCurrentSubStatus = async ({
  bankDetails,
  nbfc_id,
  setNachFailed,
  setFailureCount,
  countUp,
  setNachSuccess,
  onClose,
  reset,
  bankAccounts,
}: any) => {
  const current_sub = getCurrentSubscription(
    bankAccounts,
    bankDetails?.uuid,
    nbfc_id,
  );
  if (current_sub && current_sub?.status === 'failed') {
    setNachFailed(true);
    setFailureCount(current_sub?.failure_count || 0);
  }
  if (current_sub && ['active', 'pending'].includes(current_sub.status)) {
    setTimeout(() => {
      onClose(true);
    }, 3000);
    setNachSuccess(true);
    reset();
  }
  if (countUp >= 10) {
    setNachFailed(true);
    setFailureCount(current_sub?.failure_count || 0);
  }
};

export const getCurrentSubscription = (
  bankAccounts,
  current_bank_uuid,
  nbfc_id,
) => {
  const bankDetails = bankAccounts.find(
    (bank) => bank?.uuid == current_bank_uuid,
  );
  if (bankDetails?.subscription_details?.length > 0) {
    const current_sub = bankDetails?.subscription_details?.find(
      (subscription) => subscription.nbfc_id === nbfc_id,
    );
    return current_sub;
  }
};
