export type Mode = 'development' | 'production' | 'staging';

export const env = import.meta.env.MODE as Mode;
// this is the variable being defined in vite config
export const PARTNER_FROM_BUILD_CONFIG =
  PARTNER_CLIENT_ID && PARTNER_CLIENT_ID !== 'undefined'
    ? PARTNER_CLIENT_ID
    : 'velocity';

interface ConfigValue {
  CURRENT_ENV: Mode;
  HOST: string;
  WEBSITE_HOST: string;
  NEOBANK_HOST: string;
  MIXPANEL_TOKEN: string;
  AWS_COLLECTIONS_HOST: string;
  CASHFREE_SDK: string;
  KYC_SDK: string;
  JUSPAY_HOST: string;
  SHIPFAST_HOST: string;
}

type Config = Record<Mode, ConfigValue>;

// Please do not commit any changes to the development config
const configList: Config = {
  development: {
    CURRENT_ENV: env,
    HOST: env.CARD_API_URL || 'http://localhost:5002',
    WEBSITE_HOST: 'https://website.stagingvelocity.in',
    NEOBANK_HOST: env.NEOBANK_API_URL || 'http://localhost:5003',
    MIXPANEL_TOKEN: '8ea1677761e24f09df47be2aa74402e1',
    AWS_COLLECTIONS_HOST:
      'https://956zz6wiud.execute-api.us-east-1.amazonaws.com/staging',
    CASHFREE_SDK: 'https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js',
    KYC_SDK: 'https://app.digio.in/sdk/v10/digio.js',
    JUSPAY_HOST: 'https://sandbox.juspay.in',
    SHIPFAST_HOST: 'http://localhost:5001',
  },
  staging: {
    CURRENT_ENV: env,
    HOST: 'https://thor.stagingvelocity.in',
    WEBSITE_HOST: 'https://website.stagingvelocity.in',
    NEOBANK_HOST: 'https://banking.stagingvelocity.in',
    MIXPANEL_TOKEN: '25a2174b7a36432a9e9d5fc0fb683310',
    AWS_COLLECTIONS_HOST:
      'https://956zz6wiud.execute-api.us-east-1.amazonaws.com/staging',
    CASHFREE_SDK: 'https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js',
    KYC_SDK: 'https://app.digio.in/sdk/v10/digio.js',
    JUSPAY_HOST: 'https://sandbox.juspay.in',
    SHIPFAST_HOST: `https://shazam.stagingvelocity.in`,
    // SHIPFAST_HOST: 'https://shipfast-staging.stagingvelocity.in',
  },
  production: {
    CURRENT_ENV: env,
    HOST: 'https://thor.velocity.in',
    WEBSITE_HOST: 'https://velocity.in',
    NEOBANK_HOST: 'https://banking.velocity.in',
    MIXPANEL_TOKEN: '7878c1806aa4fe33932617be51ef459e',
    AWS_COLLECTIONS_HOST:
      'https://n7hw5fgk5i.execute-api.ap-south-1.amazonaws.com/prod',
    CASHFREE_SDK: 'https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js',
    KYC_SDK: 'https://app.digio.in/sdk/v10/digio.js',
    JUSPAY_HOST: 'https://api.juspay.in',
    SHIPFAST_HOST: 'https://shazam.velocity.in',
  },
};

export const config = configList[env];
