import { Checkbox } from 'antd';
import Flex from '@/ui-kit/flex';

import { useQuery } from '@/hooks/useQuery';

import { useShipfastOrderFilters } from '../utils';

import styles from '../shipfast_order_filters.module.less';

const ExpressTypeFilters = () => {
  const { appendParams } = useQuery();
  const paymentTypes = [{ label: 'Fast', value: 'fast' }];
  const { express } = useShipfastOrderFilters();

  const selectedPaymentType = [undefined, ''].includes(express)
    ? express
    : express === 'true'
      ? 'fast'
      : '';

  return (
    <Flex vertical gap={20}>
      {paymentTypes.map(({ label, value }) => (
        <Checkbox
          key={value}
          value={value}
          className={styles.filterCheckbox}
          checked={selectedPaymentType === value}
          onChange={(e) => {
            const value = e.target.value;
            if (e.target.checked) {
              if (value === 'fast') {
                appendParams({ express: true });
              } else {
                appendParams({ express: false });
              }
            } else {
              appendParams({
                express: '',
              });
            }
          }}
        >
          {label}
        </Checkbox>
      ))}
    </Flex>
  );
};

export default ExpressTypeFilters;
