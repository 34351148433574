import { Popover, Typography } from 'antd';
import PickupDetails from './pickup_details';
import { capitalize, ellipsisLongString } from '@/ve-utils/common';
import {
  getWareHoouseFullAddress,
  useShipfastWarehouseDetails,
} from '@/swr/shipfast';

import { ColumnTextHeader } from '@/ui-kit/shipping/typography';

const { Paragraph } = Typography;

type Props = {
  handleEdit?: () => void;
  warehouseId: string;
  showFullAddress?: boolean;
};

const WarehouseInfo = ({
  handleEdit,
  warehouseId,
  showFullAddress = false,
}: Props) => {
  const { warehouseDetails } = useShipfastWarehouseDetails(warehouseId);

  return (
    <Popover
      placement="bottom"
      content={
        <PickupDetails warehouseId={warehouseId} handleEdit={handleEdit} />
      }
      trigger="hover"
    >
      <div>
        <ColumnTextHeader isHoverable>
          {ellipsisLongString(
            capitalize(warehouseDetails?.attributes?.name),
            20,
          )}
        </ColumnTextHeader>
        {showFullAddress && (
          <Paragraph
            type="secondary"
            ellipsis={{ tooltip: true, rows: 2 }}
            style={{ fontSize: 14 }}
          >
            {getWareHoouseFullAddress(warehouseDetails)}
          </Paragraph>
        )}
      </div>
    </Popover>
  );
};

export default WarehouseInfo;
