import { makeFileNameEllipsis } from '@/ve-utils/common';
import { Tooltip } from 'antd';
import cookie from 'cookie';
import {
  MasterCardIcon,
  RupayIcon,
  VisaIcon,
} from '@Velocity-Engineering/ve-icons';

import { API, CURRENT_ENV } from '@/utils/api_config';

export function isServer() {
  return typeof window === 'undefined';
}

export function env() {
  return CURRENT_ENV; //it can be development / production / devserver
}

export function isProduction() {
  return env() === 'production';
}

export function isStaging() {
  return env() === 'staging';
}

export function isDevelopment() {
  return env() === 'development';
}

export const twoPlaceDecimal = (amount) => {
  return parseFloat(amount).toFixed(2);
};

export function getServiceAccount(
  vaDetails = [],
  internal_service = 'payments',
) {
  if (internal_service === 'payments')
    return vaDetails.find((acc) => acc.internal_service === internal_service);
  return vaDetails.filter((acc) => acc.internal_service === internal_service);
}

export const isReceivablesEnabled = (profile) => {
  const { applications } = profile;
  const paymentApplication = applications.find(
    (app) => app.application_type === 'payments',
  );
  return !!paymentApplication?.company_details?.isVendor;
};

export const ellipsisWithTooltip = (word, len) => {
  const ellipsisText = makeFileNameEllipsis(word, len);
  if (word?.length > len) {
    return (
      <Tooltip title={word} placement="top">
        {ellipsisText}
      </Tooltip>
    );
  } else return word;
};

export const groupArrayByKey = (array, key) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
};

export const addToArray = (array, newItem) => {
  const indexOfNewItem = array.findIndex((a) => a.uuid === newItem.uuid);
  if (indexOfNewItem < 0) {
    return [...array, newItem];
  }

  return array;
};

export const removeFromArray = (array, newItem) => {
  return array.filter((a) => a.uuid !== newItem.uuid);
};

// needs paginatuin meta data as input and gives if it has more data to be loaded or not
export const hasMore = (meta) => {
  const { current_page, per_page, total } = meta;
  if (total && per_page && current_page) {
    return total > current_page * per_page;
  }
  return true;
};
export function makePdfThumb(page, width = 96) {
  var vp = page.getViewport({ scale: 1, dontFlip: true });
  var canvas = document.createElement('canvas');
  canvas.width = canvas.height = width;
  var scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);

  return page
    .render({
      canvasContext: canvas.getContext('2d'),
      viewport: page.getViewport({ scale }),
    })
    .promise.then(function () {
      return canvas;
    });
}

export function omitKeysInObject(obj, values = [null, undefined]) {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => !values.includes(value)),
  );
}

export const showDashOnNull = (text) => {
  return [undefined, null].includes(text) ? '---' : text;
};

export const getSelectedVendor = (option, data = []) => {
  const selected = data.find((vendor) => {
    return vendor.uuid === option.uuid;
  });
  return selected;
};

export const downloadBlob = (blob, name) => {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  link.click();

  // Remove link from body
  document.body.removeChild(link);
};

const cookies = cookie.parse(document.cookie);

export const jwt = cookies.jwt && JSON.parse(cookies.jwt);

export const checkCardNetwork = (cardNumber) => {
  const trimmedCardNumber = cardNumber?.replace(/\s/g, '');
  const visaRegex = /^4[0-9]{6,}$/;
  const mastercardRegex =
    /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;

  if (visaRegex.test(trimmedCardNumber)) {
    return 'visa';
  } else if (mastercardRegex.test(trimmedCardNumber)) {
    return 'mastercard';
  }
  return undefined;
};

export const getUpiPaymentQRCode = (paymentLinkCode) => {
  const baseUrl = isProduction()
    ? `https://djgb0joesukj8.cloudfront.net`
    : `https://d3t6ml01vlxhd6.cloudfront.net`;
  return `${baseUrl}/b2b-payments-qrcode-${paymentLinkCode}.svg`;
};

export const getUserIpAddress = async () => {
  try {
    const url = API.payments.getUserIpAddress;
    const response = await fetch(url);
    const data = await response.json();
    return data?.ip;
  } catch (error) {
    return '';
  }
};

export const GetCardNetworkIcon = ({ cardNetwork }) => {
  switch (cardNetwork) {
    case 'visa':
      return <VisaIcon />;
    case 'mastercard':
      return <MasterCardIcon />;
    case 'rupay':
      return <RupayIcon />;
    default:
      return <></>;
  }
};
