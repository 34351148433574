import { Checkbox } from 'antd';
import Flex from '@/ui-kit/flex';
import { useQuery } from '@/hooks/useQuery';

import styles from '../shipfast_order_filters.module.less';
import { useShipfastOrderFilters } from '../utils';

const PaymentTypeFilter = () => {
  const { appendParams } = useQuery();
  const paymentTypes = [
    { label: 'Pre-Paid', value: 'paid' },
    { label: 'COD', value: 'unpaid' },
  ];
  const { is_cod } = useShipfastOrderFilters();

  const selectedPaymentType = [undefined, ''].includes(is_cod)
    ? is_cod
    : is_cod === 'true'
      ? 'unpaid'
      : 'paid';

  return (
    <Flex vertical gap={20}>
      {paymentTypes.map(({ label, value }) => (
        <Checkbox
          key={value}
          value={value}
          className={styles.filterCheckbox}
          checked={selectedPaymentType === value}
          onChange={(e) => {
            const value = e.target.value;
            if (e.target.checked) {
              if (value === 'paid') {
                appendParams({ is_cod: false });
              } else {
                appendParams({ is_cod: true });
              }
            } else {
              appendParams({
                is_cod: '',
              });
            }
          }}
        >
          {label}
        </Checkbox>
      ))}
    </Flex>
  );
};

export default PaymentTypeFilter;
