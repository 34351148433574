import { useShipfastChannels } from '@/swr/shipfast';
import { Checkbox } from 'antd';
import Flex from '@/ui-kit/flex';
import { capitalize } from '@/ve-utils/common';
import { Loader } from '@/ui-kit';
import { useQuery } from '@/hooks/useQuery';

import { useShipfastOrderFilters } from '../utils';

import styles from '../shipfast_order_filters.module.less';

const SalesChannelFilter = () => {
  const { appendParams, removeMultiValueParam } = useQuery();
  const { channels, isLoading } = useShipfastChannels();
  const { selectedChannels } = useShipfastOrderFilters();

  if (isLoading) return <Loader />;

  return (
    <Flex vertical gap={20}>
      {channels.map((channel) => (
        <Checkbox
          key={channel}
          value={channel}
          className={styles.filterCheckbox}
          checked={selectedChannels?.includes(channel)}
          onChange={(e) => {
            const value = e.target.value;
            if (e.target.checked) {
              appendParams({ channel: [...selectedChannels, value].join(',') });
            } else {
              removeMultiValueParam({ key: 'channel', value });
            }
          }}
        >
          {capitalize(channel)}
        </Checkbox>
      ))}
    </Flex>
  );
};

export default SalesChannelFilter;
