export function convertArray2Map(array) {
  let ids = [];
  let map = {};

  array.forEach((element) => {
    const { uuid } = element;

    ids.push(uuid);
    map[uuid] = element;
  });

  return {
    ids,
    map,
  };
}
