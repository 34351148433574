import classNames from 'classnames';
import type { CSSProperties } from 'react';

type FlexJustify =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
type FlexAlign = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  prefixCls?: string;
  vertical?: boolean;
  wrap?: FlexWrap;
  justify?: FlexJustify;
  align?: FlexAlign;
  flex?: string | number;
  gap?: number | [number, number];
  direction?: FlexDirection;
  component?: keyof JSX.IntrinsicElements | React.ComponentType;
  children?: React.ReactNode;
}

const Flex = (props: FlexProps) => {
  const {
    prefixCls = 'ant-flex',
    className,
    style,
    flex,
    gap,
    vertical = false,
    wrap,
    justify,
    align,
    direction,
    component: Component = 'div',
    children,
    ...others
  } = props;

  const mergedStyle: CSSProperties = {
    display: 'flex',
    flexDirection: direction || (vertical ? 'column' : 'row'),
    flexWrap: wrap,
    justifyContent: justify,
    alignItems: align,
    flex,
    gap,
    ...style,
  };

  const classes = classNames(prefixCls, className);

  return (
    <Component className={classes} style={mergedStyle} {...others}>
      {children}
    </Component>
  );
};

export default Flex;
