import dayjs from 'dayjs';

import TagsFilter from './components/tags_filter';
import PaymentTypeFilter from './components/payment_type_filter';
import PickupAddressFilter from './components/pickup_address_filter';
import SalesChannelFilter from './components/sales_channel_filter';

import { useQuery } from '@/hooks/useQuery';
import { removeEmptyStringFromArray } from '@/ve-utils/common';
import CityFilter from './components/city_filter';
import ExpressTypeFilters from './components/express_filters';

export const filterComponentMap = {
  // will be added after sub status api's are added
  //   status: {
  //     label: 'Status',
  //     component: <div>Status</div>,
  //   },
  salesChannel: {
    label: 'Sales Channel',
    component: <SalesChannelFilter />,
  },
  paymentType: {
    label: 'Payment Type',
    component: <PaymentTypeFilter />,
  },
  warehouseId: {
    label: 'Pickup Address',
    component: <PickupAddressFilter />,
  },
  tags: {
    label: 'Tags',
    component: <TagsFilter />,
  },
  cityFilter: {
    label: 'City',
    component: <CityFilter />,
  },
  express: {
    label: 'Delivery Type',
    component: <ExpressTypeFilters />,
  },
};

export type FilterType = keyof typeof filterComponentMap;

export const useShipfastOrderFilters = () => {
  const { query } = useQuery();
  const { warehouseId, channel, tags, is_cod, city, express } = query;

  const selectedWarehouseIds = removeEmptyStringFromArray(
    warehouseId?.split(',') || [],
  );
  const selectedChannels = removeEmptyStringFromArray(
    channel?.split(',') || [],
  );
  const selectedTags = removeEmptyStringFromArray(tags?.split(',') || []);
  const countMap: Record<FilterType, number> = {
    paymentType: is_cod ? 1 : 0,
    warehouseId: selectedWarehouseIds.length,
    salesChannel: selectedChannels.length,
    tags: selectedTags.length,
    city: city?.length > 0,
    express: express ? 1 : 0,
  };

  const totalFilterCount = Object.values(countMap).reduce(
    (acc, item) => acc + item,
    0,
  );
  return {
    is_cod,
    selectedWarehouseIds,
    selectedChannels,
    selectedTags,
    countMap,
    totalFilterCount,
    city,
    express,
  };
};

export const useShipfastDateFilters = () => {
  const { query } = useQuery();
  const { dateFilter, start_date, end_date } = query;
  const dateFilterOptions = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Today',
      value: 'today',
    },
    {
      label: 'Last 7 days',
      value: 'last_7_days',
    },
    {
      label: 'Last 30 days',
      value: 'last_30_days',
    },
  ];
  const selectedDateFilter = dateFilter;
  const dateFormat = 'YYYY-MM-DD';
  const dateFilterMap = {
    today: {
      from: dayjs().startOf('day').unix(),
      to: dayjs().endOf('day').unix(),
    },
    last_7_days: {
      from: dayjs().subtract(7, 'day').startOf('day').unix(),
      to: dayjs().endOf('day').unix(),
    },
    last_30_days: {
      from: dayjs().subtract(30, 'day').startOf('day').unix(),
      to: dayjs().endOf('day').unix(),
    },
  };

  const from = start_date ? dayjs(start_date).startOf('day').unix() : undefined;
  const to = end_date ? dayjs(end_date).endOf('day').unix() : undefined;

  const selectedDateFilterData =
    selectedDateFilter === 'all'
      ? undefined
      : dateFilterMap[selectedDateFilter as keyof typeof dateFilterMap];
  return {
    dateFilterOptions,
    from: from || selectedDateFilterData?.from,
    to: to || selectedDateFilterData?.to,
  };
};
