import { config } from '../config';

const { SHIPFAST_HOST, HOST } = config;

const shopifyIntegration = `${HOST}/public/integrations/shopify`;

export const SHIPFAST_API_LIST = {
  getOrders: `${SHIPFAST_HOST}/api/v1/shipments`,
  getDeliveredOrders: `${SHIPFAST_HOST}/api/v1/orders`,
  getOrder: (id: string) => `${SHIPFAST_HOST}/api/v1/shipments/${id}`,
  getOrderDetails: (id: string) => `${SHIPFAST_HOST}/api/v1/orders/${id}`,
  getOrderPricing: (orderId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${orderId}/charges`,
  getCarriers: `${SHIPFAST_HOST}/api/v1/carriers`,
  createShipment: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/ship`,
  cancelOrder: (id: string) => `${SHIPFAST_HOST}/api/v1/orders/${id}/cancel`,
  cancelShipment: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/cancel`,
  selfFulfillment: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/orders/${id}/mark_as_paid`,
  patchOrder: (id: string) => `${SHIPFAST_HOST}/api/v1/orders/${id}`,
  getWarehousePickupList: `${SHIPFAST_HOST}/api/v1/warehouses`,
  initiateRto: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${id}/return_to_origin`,
  getStatusSummary: `${SHIPFAST_HOST}/api/v1/shipments/status-summary`,

  // Manifest
  getPickups: `${SHIPFAST_HOST}/api/v1/pickups`,
  getPickup: (pickupId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${pickupId}`,
  getPickupDetails: (pickupId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${pickupId}`,
  bulkCreateShipments: `${SHIPFAST_HOST}/api/v1/shipments/bulk-create`,
  createBulkManifests: (pickupId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${pickupId}/manifests`,
  createReturn: (orderId: string) =>
    `${SHIPFAST_HOST}/api/v1/orders/${orderId}/return`,
  getReturns: `${SHIPFAST_HOST}/api/v1/returns`,
  getReturnsStatusSummary: `${SHIPFAST_HOST}/api/v1/returns/status-summary`,
  acceptReturn: (returnId: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${returnId}/accept`,
  rejectReturn: (returnId: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${returnId}/reject`,
  verifyDelivery: (returnId: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${returnId}/verify`,
  shipmentLabel: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/label`,
  orderInvoice: (orderId: string) =>
    `${SHIPFAST_HOST}/api/v1/orders/${orderId}/invoice`,
  manifestDownload: (manifestId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${manifestId}/manifests/download`,
  downloadPreviousManifest: (pickupId: string, manifestId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${pickupId}/manifests/${manifestId}/download`,

  cloneOrder: (orderId: string) =>
    `${SHIPFAST_HOST}/api/v1/orders/${orderId}/clone`,
  shipfastChannels: `${SHIPFAST_HOST}/api/v1/channels`,
  shipfastTags: `${SHIPFAST_HOST}/api/v1/orders/tags`,
  bulkCreateOrders: `${SHIPFAST_HOST}/api/v1/orders/bulk-create`,
  getDashboardDetails: `${SHIPFAST_HOST}/api/v1/dashboard`,
  aggregatedManifests: (pickupId: string) =>
    `${SHIPFAST_HOST}/api/v1/pickups/${pickupId}/download`,
  generateInvoices: `${SHIPFAST_HOST}/api/v1/orders/invoices`,
  generateLabels: `${SHIPFAST_HOST}/api/v1/shipments/labels`,
  downloadDocumentJob: (jobId: string) =>
    `${SHIPFAST_HOST}/api/v1/download_job/${jobId}`,
  getReturn: (returnId: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${returnId}`,
  patchReturnWarehousePickupId: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${id}`,
  getProfile: `${SHIPFAST_HOST}/api/v1/profile`,
  getReturnsPricing: (returnId: string) =>
    `${SHIPFAST_HOST}/api/v1/returns/${returnId}/charges`,
  walletRecharge: `${SHIPFAST_HOST}/api/v1/wallet_recharges`,
  walletRechargeStatus: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/wallet_recharges/${id}/check_status`,
  getInvoices: `${SHIPFAST_HOST}/api/v1/invoices`,
  getInvoice: (id: string) => `${SHIPFAST_HOST}/api/v1/invoices/${id}`,
  getLedgerEvents: `${SHIPFAST_HOST}/api/v1/ledger_events`,
  getFilteredLedgerEvents: `${SHIPFAST_HOST}/api/v1/ledger_events`,
  getLedgerEvenstFiltered: `${SHIPFAST_HOST}/api/v1/ledger_event/filtered`,
  getCodRemittances: `${SHIPFAST_HOST}/api/v1/cod_settlements`,
  getCodRemittance: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/cod_settlements/${id}`,
  getCodRemittancesDownload: ({ start, end }: { start: number; end: number }) =>
    `${SHIPFAST_HOST}/api/v1/cod_settlements/download?start_time=${start}&end_time=${end}`,
  getLedgerEventDownload: ({ start, end }: { start: number; end: number }) =>
    `${SHIPFAST_HOST}/api/v1/ledger_events/download?start_time=${start}&end_time=${end}`,
  reAttemptShipmentDelivery: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/reattempt_delivery`,
  updateShipfastProfile: `${SHIPFAST_HOST}/api/v1/profile-update`,
  stateList: `${SHIPFAST_HOST}/api/v1/states`,
  shopifyRedirect: ({
    shopifyUrl,
    redirectUrl,
    companyUuid,
  }: {
    shopifyUrl: string;
    redirectUrl: string;
    companyUuid: string;
  }) => {
    const url = shopifyIntegration;
    const securedUrl = url.replace('http://', 'https://');
    return `${securedUrl}?shop=${shopifyUrl}&company_uuid=${companyUuid}&application_type=shipfast&redirect_uri=${redirectUrl}`;
  },
  getPackages: `${SHIPFAST_HOST}/api/v1/packages`,
  patchReturn: (id: string) => `${SHIPFAST_HOST}/api/v1/returns/${id}`,
  getShipmentsEventDownload: ({ start, end }: { start: number; end: number }) =>
    `${SHIPFAST_HOST}/api/v1/shipments/download?start_time=${start}&end_time=${end}`,

  // ai integration
  getVaniAgents: `${SHIPFAST_HOST}/api/v1/agents`,
  triggerCall: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/calls`,
  triggerBulkCalls: `${SHIPFAST_HOST}/api/v1/calls/bulk_create`,
  getVaniCalls: (shipmentId: string) =>
    `${SHIPFAST_HOST}/api/v1/shipments/${shipmentId}/calls`,

  // shipping rules
  getShippingRules: `${SHIPFAST_HOST}/api/v1/shipping_rules`,
  createShippingRule: `${SHIPFAST_HOST}/api/v1/shipping_rules`,
  getRuleCarriers: `${SHIPFAST_HOST}/api/v1/client_carriers`,
  getRuleCarrierSummary: `${SHIPFAST_HOST}/api/v1/client_carriers/summary`,
  bulkUpdateShippingRules: `${SHIPFAST_HOST}/api/v1/shipping_rules/bulk_update`,

  //settings
  getCoomunicationConfig: `${SHIPFAST_HOST}/api/v1/communication_configs`,
  getBankAccounts: `${SHIPFAST_HOST}/api/v1/bank_accounts`,
  getInvoiceAddresses: `${SHIPFAST_HOST}/api/v1/addresses`,
  getWarehouseAddress: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/addresses/${id}`,
  getProducts: `${SHIPFAST_HOST}/api/v1/products`,
  uploadJob: `${SHIPFAST_HOST}/api/v1/upload_jobs`,

  //weight discrepancy
  getWeightDiscrepancySummary: `${SHIPFAST_HOST}/api/v1/weight_discrepancies/summary`,
  getWeightDiscrepancies: `${SHIPFAST_HOST}/api/v1/weight_discrepancies`,
  approveWeightDiscrepancy: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/weight_discrepancies/${id}/approve`,
  escalateWeightDiscrepancy: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/weight_discrepancies/${id}/escalate`,
  raiseWeightDiscrepancyDispute: (id: string) =>
    `${SHIPFAST_HOST}/api/v1/weight_discrepancies/${id}/challenge`,
};

export const getCurrentHost = () => {
  return window.location.origin + window.location.pathname;
};
