import { useShipfastTags } from '@/swr/shipfast';
import { Checkbox } from 'antd';
import Flex from '@/ui-kit/flex';
import { capitalize } from '@/ve-utils/common';
import { Loader } from '@/ui-kit';
import { useQuery } from '@/hooks/useQuery';

import { useShipfastOrderFilters } from '../utils';

import styles from '../shipfast_order_filters.module.less';

const TagsFilter = () => {
  const { appendParams, removeMultiValueParam } = useQuery();
  const { tags, isLoading } = useShipfastTags();
  const { selectedTags } = useShipfastOrderFilters();

  if (isLoading) return <Loader />;

  return (
    <Flex vertical gap={20}>
      {tags.map((tag) => (
        <Checkbox
          key={tag}
          value={tag}
          className={styles.filterCheckbox}
          checked={selectedTags?.includes(tag)}
          onChange={(e) => {
            const value = e.target.value;
            if (e.target.checked) {
              appendParams({ tags: [...selectedTags, value].join(',') });
            } else {
              removeMultiValueParam({ key: 'tags', value });
            }
          }}
        >
          {capitalize(tag)}
        </Checkbox>
      ))}
    </Flex>
  );
};

export default TagsFilter;
