import { useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import { Link, useRouteError } from 'react-router-dom';

import { GreenButton } from '@/ui-kit';

import { trackMixpanelEvent } from '@/utils';

import styles from './index.module.less';

function SomethingWentWrong() {
  const error = useRouteError();

  useEffect(() => {
    if (!error) return;
    trackMixpanelEvent('shipfast_500_error', {
      errorObj: `${error}`,
    });
  }, [error]);

  return (
    <div className={styles.pageNotFoundContainer}>
      <img
        src={'https://assets-ve.s3.us-east-2.amazonaws.com/500.svg'}
        alt="page not dfound"
        className={styles.image}
      />
      <br />
      <Title level={2}>Oops! Something went wrong.</Title>
      <Title level={5}>The page you requested could not be found.</Title>
      <br />
      <Link to="/product">
        <GreenButton>GO BACK HOME</GreenButton>
      </Link>
    </div>
  );
}

export default SomethingWentWrong;
