import {
  useIsOnboardingDone,
  useShipfastDashboardDetails,
} from '@/swr/shipfast';
import { Loader } from '@/ui-kit';
import { Outlet } from 'react-router-dom';

const DashboardLayout = () => {
  const { isLoading } = useShipfastDashboardDetails();

  const { isLoading: isOnboardingLoading } = useIsOnboardingDone();

  if (isLoading || isOnboardingLoading) return <Loader />;

  return <Outlet />;
};

export default DashboardLayout;
