import { Loader } from '@/ui-kit';
import { Suspense } from 'react';

import PageNotFound from '@/pages/page_not_found/page_not_found';
import SomethingWentWrong from '@/pages/something_went_wrong';

export const LazyELement = ({ children }: { children: JSX.Element }) => (
  <Suspense fallback={<Loader />}>{children}</Suspense>
);

export const errorElement = <PageNotFound />;
export const error500Element = <SomethingWentWrong />;
